import { Component, OnInit, Inject } from '@angular/core';
import { SnackbarService } from 'src/app/services/snackBar/snackbar.service';
import { FormBuilder, UntypedFormGroup, Validators, NgForm, FormsModule } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogModule as MatDialogModule, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { UserService } from 'src/app/services/user/user.service';
import { HttpClient } from '@angular/common/http';
import { ResetPasswordModel } from 'src/app/model/reset-password.model';
import { JwtService } from 'src/app/services/core/jwt.service';
import { EncrDecrService } from 'src/app/services/EncrDecr/encr-decr.service';
import { environment } from 'src/environments/environment';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { NgClass, NgIf } from '@angular/common';
import { FlexModule } from '@angular/flex-layout/flex';

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss'],
    standalone: true,
    imports: [FlexModule, FormsModule, NgClass, ExtendedModule, NgIf]
})
export class ResetPasswordComponent implements OnInit {
  resetForm: UntypedFormGroup;
  submitted: boolean = false;
  notMatch: boolean = false;
  loading: boolean = false;
  id: string = '';
  OldPassword: any;
  constructor(
    private dialogRef: MatDialogRef<ResetPasswordComponent>,
    private snackbar: SnackbarService,    
    private router: Router,
    private route: ActivatedRoute,
    private http: HttpClient,
    private userService: UserService,
    private jwtService: JwtService,
    private EncrDecr: EncrDecrService,
    ) { this.intializePrefilledData();
     }
    
     formModel: ResetPasswordModel = new ResetPasswordModel();
  
  intializePrefilledData()
  {
    
    //this.Email = this.formModel.Email;
    
    // this.VideoScreenSer.formData.ClientName=this.videoScreenDetails.InterviewModel.ClientName;
    // this.VideoScreenSer.formData.InterviewTitle=this.videoScreenDetails.InterviewModel.InterviewTitle;
    // this.VideoScreenSer.formData.InterviewTimezone=this.videoScreenDetails.InterviewModel.InterviewTimezone;
    // this.VideoScreenSer.formData.InterviewDateTime=this.videoScreenDetails.InterviewModel.InterviewDateTime;
    // this.VideoScreenSer.formData.InterviewId=this.videoScreenDetails.InterviewModel.InterviewId;
  }
  // ngOnInit() {
  // }
  get f() {
    return this.resetForm.controls;
  }
  // formInit() {
  //   this.resetForm = this._formbuilder.group({
  //     password: ['', [Validators.required, Validators.minLength(6), Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{5,}')]],
  //     re_password: ['', [Validators.required, Validators.minLength(6), Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{5,}')]]
  //   });
  // }

  ngOnInit() {
    // this.resetForm = this.formbuilder.group({
    //   'OldPassword': [null, [Validators.required]],
    //   'NewPassword': [null, [Validators.required, Validators.pattern('^(?=.*[A-Z])(?=.*[@#$%^&+=]).*$')]],
    //   'ConfirmNewPassword': [null, [Validators.required, Validators.pattern('^(?=.*[A-Z])(?=.*[@#$%^&+=]).*$')]]
    // })
  }
  onSubmit(form: NgForm) {
      this.submitted = true;
      form.controls['OldPassword'].value
      if (form.controls['NewPassword'].value === form.controls['ConfirmNewPassword'].value) {
        this.notMatch = false;
        if (form.valid) {
          this.loading = true;
          let params = {
            // email: this.EncrDecr.set(environment.EncryptKey, this.jwtService.getUserEmail()),
            // oldPassword: this.EncrDecr.set(environment.EncryptKey, form.controls.OldPassword.value),
            // newPassword: this.EncrDecr.set(environment.EncryptKey, form.controls.OldPassword.value)
            email: this.jwtService.getUserEmail(),
            oldPassword: form.controls.OldPassword.value,
            newPassword: form.controls.NewPassword.value
          }
          this.userService.resetPassword(params).subscribe(response => {
        

            console.log(response);
            try {
              if (response.success == true) {
                
                this.loading = false;
                //localStorage.setItem('islogin', 'true');
                //document.getElementById('appHome').removeAttribute('class') ;
                //document.getElementById('appHome').setAttribute('class','wrapper') ;
                
               
                //const email = this.jwtService.getUserEmail();
                if (response.message) {
                  this.onDismiss();
                  this.snackbar.openSnackBar(response.message);
                }
               
                
              }
              else {
                this.loading = false;
                if (response.message) {
                  this.snackbar.openSnackBar(response.message);
                } else {
                  console.log('some error occured');
                  
                }
              }
            }
            catch (e) {
              this.loading = false;
              this.snackbar.openSnackBar('some error occured');
            }
            //this.isProcessing = false;
          }, (err) => {
            this.loading = false;
            console.log(err);
            let response = err;
            //this.isProcessing = false;
            if (response.Message) {
              this.snackbar.openSnackBar(response.Message);
            } else {
              this.snackbar.openSnackBar('some error occured');
            }
          }, () => {
            this.loading = false;
            //this.isProcessing = false;
          })
        } else {
          return false;
        }
      } else {
        this.notMatch = true;
        return false;
      }
    }
    onDismiss(): void {
      // Close the dialog, return false
      this.dialogRef.close(false);
    }

  // onSubmit() {
  //   this.submitted = true;
  //   if (this.resetForm.controls['password'].value === this.resetForm.controls['re_password'].value) {
  //     this.notMatch = false;
  //     if (this.resetForm.valid) {
  //       this.loading = true;
  //       try {
  //         this._API.resetPassword(this.resetForm.value, this.id).subscribe((res: any) => {
  //           this.loading = false;
  //           if (res.success) {
  //             this._toastr.successToastr(res.message, 'Done!');
  //           } else {
  //             this._toastr.errorToastr(res.message, 'Error!');
  //           }
  //         });
  //       } catch (error) {
  //         throw error;
  //       }
  //       this.formInit();
  //       this._router.navigate(['/']);
  //     } else {
  //       return false;
  //     }
  //   } else {
  //     this.notMatch = true;
  //     return false;
  //   }
  // }

}
