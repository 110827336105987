import { enableProdMode, Injectable, importProvidersFrom } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { env } from 'process';

import 'zone.js';

import { NoCacheHeadersInterceptor } from './app/app.module';
import { environment } from './environments/environment';
import { AppComponent } from './app/app.component';
import { BackButtonDisableModule } from 'angular-disable-browser-back-button';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { MatSortModule } from '@angular/material/sort';
import { NgxLoadingModule } from 'ngx-loading';
import { MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { CodemirrorModule } from 'ng2-codemirror';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { MatStepperModule } from '@angular/material/stepper';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { NgcCookieConsentConfig, NgcCookieConsentModule } from 'ngx-cookieconsent';
import { ServiceWorkerModule } from '@angular/service-worker';
import { provideAnimations } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app/routing/app-routing.module';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { MAT_LEGACY_FORM_FIELD_DEFAULT_OPTIONS as MAT_FORM_FIELD_DEFAULT_OPTIONS, MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { EncrDecrService } from './app/services/EncrDecr/encr-decr.service';
import { IpServiceService } from 'src/app/services/ip-service.service';
import { ChatService } from './app/services/chat.service';
import { HTTP_INTERCEPTORS, HttpInterceptor, HttpRequest, HttpHandler, withInterceptorsFromDi, provideHttpClient } from '@angular/common/http';
import { MatNativeDateModule } from "@angular/material/core";
import { LightgalleryModule } from 'lightgallery/angular';

// const authReq = req.clone({
//       setHeaders: {
//         'Cache-Control': 'no-cache, no-store, must-revalidate',
//         Pragma: 'no-cache',
//         Expires:'0'
//       }
//     });
const cookieConfig:NgcCookieConsentConfig = {
  container: document.getElementById("cookieconsent"),
  cookie: {
    domain: `${environment.appDomain}`, 
    //name: crypto.randomUUID(),
    expiryDays:parseInt(`${environment.cookiesExpiryDays}`),
    secure: true
  },
  palette: {
    popup: {
      background: '#0F4E93'
    },
    button: {
      background: '#563d6f'
    }
  },
  theme: 'edgeless',
  type: 'opt-out',
  position: "bottom",
  revokable: true,
  
  content: {
    "header": 'Cookies used on the website!',
    "message": 'This website uses cookies to improve your experience.',
    "dismiss": 'Got it!',
    "allow": 'Allow cookies',
    "deny": 'Decline',
    "link": 'Learn more',
    "href": 'https://veriklick.com/privacy-policy/',
    "close": '&#x274c;',
    "policy": 'Revoke Cookies Consent',
    "target": '_blank',
    }
};



if (environment.production) {
  console.log('pro mode');
  enableProdMode();
  //Disable the log applicationwise in production mode on
  if(!environment.EnableLog)
  {
    console.log = function () { return false; }
  }
}

document.addEventListener('DOMContentLoaded', () => {
  bootstrapApplication(AppComponent, {
    providers: [
        importProvidersFrom(BrowserModule.withServerTransition({ appId: 'veriklick' }), AppRoutingModule, ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }), LoadingBarModule, LoadingBarRouterModule, MatSelectModule, MatIconModule, MatTabsModule, MatStepperModule, MatSnackBarModule, MatBottomSheetModule, MatMenuModule, CodemirrorModule, MatDialogModule, MatInputModule, 
        //FormBuilder,
        MatTooltipModule, FormsModule, ReactiveFormsModule, MatFormFieldModule, MatInputModule, MatPaginatorModule, NgxLoadingModule.forRoot({}), 
        //CoreModule,
        MatSortModule, OwlDateTimeModule,MatNativeDateModule,LightgalleryModule, OwlNativeDateTimeModule, BackButtonDisableModule.forRoot({
            preserveScrollPosition: true
        }), NgcCookieConsentModule.forRoot(cookieConfig)),
        {
            provide: HTTP_INTERCEPTORS,
            useClass: NoCacheHeadersInterceptor,
            multi: true
        },
        ChatService,
        IpServiceService,
        EncrDecrService,
        { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { float: 'never' } },
        provideAnimations(),
        provideHttpClient(withInterceptorsFromDi())
    ]
})
    .catch(err => console.error(err));
});
