import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import {bootstrapApplication} from '@angular/platform-browser'
import { AppRoutingModule } from './routing/app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

import { LoadingBarModule } from '@ngx-loading-bar/core';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';

//import { HttpClientModule } from '@angular/common/http';

// ANGULAR MATERIAL
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacySnackBarModule as MatSnackBarModule, MAT_LEGACY_SNACK_BAR_DATA as MAT_SNACK_BAR_DATA } from '@angular/material/legacy-snack-bar';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';

// COMPONENTS
import { HeaderComponent } from './components/common/header/header.component';
import { FooterComponent } from './components/common/footer/footer.component';
import { SnackBarComponent } from './services/snackBar/snack-bar.component';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MAT_LEGACY_FORM_FIELD_DEFAULT_OPTIONS as MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/legacy-form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatStepperModule } from '@angular/material/stepper';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';

import { LogoComponent } from './components/common/header/logo/logo.component';
import { CodemirrorModule } from 'ng2-codemirror'; 
// import { ResetPasswordComponent } from './components/recruiter/home/reset-password/reset-password.component';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';


import { ChatService } from './services/chat.service';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
//import { DownloadDirective } from './services/download.directive';
import { BackButtonDisableModule } from 'angular-disable-browser-back-button';
import { EncrDecrService } from './services/EncrDecr/encr-decr.service';
//import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { PresetFilterPopupComponent } from './components/report/preset-filter/preset-filter-popup/preset-filter-popup.component';
import { AddPresetFilterComponent } from './components/report/preset-filter/add-preset-filter/add-preset-filter.component';
import { MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { NgxLoadingModule } from 'ngx-loading';
import { HttpHandler, HttpInterceptor, HttpRequest, HttpClientModule,HTTP_INTERCEPTORS } from '@angular/common/http';
import {NgcCookieConsentModule,NgcCookieConsentConfig} from 'ngx-cookieconsent';
import { IpServiceService } from 'src/app/services/ip-service.service';

import { MatSortModule } from '@angular/material/sort';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';




@Injectable({
  providedIn: 'root'
})
 //Manu Datta: 20210515, for browser caching
export class NoCacheHeadersInterceptor implements HttpInterceptor {
intercept(req: HttpRequest<any>, next: HttpHandler) {
    const authReq = req.clone({
      setHeaders: {
        'Cache-Control': 'no-cache, no-store, must-revalidate',
        Pragma: 'no-cache',
        Expires:'0'
      }
    });
    return next.handle(authReq);
  }
}

const cookieConfig:NgcCookieConsentConfig = {
  container: document.getElementById("cookieconsent"),
  cookie: {
    domain: `${environment.appDomain}`, 
    //name: crypto.randomUUID(),
    expiryDays:parseInt(`${environment.cookiesExpiryDays}`),
    secure: true,// or 'your.domain.com' // it is mandatory to set a domain, for cookies to work properly (see https://goo.gl/S2Hy2A)
  },
  palette: {
    popup: {
      background: '#0F4E93'
    },
    button: {
      background: '#563d6f'
    }
  },
  theme: 'edgeless',
  type: 'opt-out',
  position: "bottom",
  revokable: true,
  
  content: {
    "header": 'Cookies used on the website!',
    "message": 'This website uses cookies to improve your experience.',
    "dismiss": 'Got it!',
    "allow": 'Allow cookies',
    "deny": 'Decline',
    "link": 'Learn more',
    "href": 'https://veriklick.com/privacy-policy/',
    "close": '&#x274c;',
    "policy": 'Revoke Cookies Consent',
    "target": '_blank',
    }
};
@NgModule({
  declarations: [
  ],
  imports: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    SnackBarComponent,
    LogoComponent,
    PresetFilterPopupComponent,
    AddPresetFilterComponent,
  ],
  providers: [
    { provide: MAT_SNACK_BAR_DATA, useValue: {} },
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'fill' } },
    { provide: HTTP_INTERCEPTORS, useClass: NoCacheHeadersInterceptor, multi: true },
    FormBuilder,
    ChatService,
    EncrDecrService,
    IpServiceService,
  ],
  // bootstrap: [AppComponent],
})


export class AppModule { }

bootstrapApplication(AppComponent);
