import {EventEmitter, Injectable} from '@angular/core';
import * as Twilio from 'twilio-chat';
import {Client} from "twilio-chat";
//import {Util} from "../util/util";
import {Channel} from "twilio-chat/lib/channel";
import {Router} from "@angular/router";
import { Observable } from 'rxjs';
import { ApiService } from './core/api.service';
//import {AuthService} from "./auth.service";
import { apiPaths } from '../setting/apiPath';

@Injectable({
  providedIn: 'root'
})
export class ChatService {

  public chatClient: Client;
  public currentChannel: Channel;
  public chatConnectedEmitter: EventEmitter<any> = new EventEmitter<any>()
  public chatDisconnectedEmitter: EventEmitter<any> = new EventEmitter<any>()
  //Twilio:any;
  constructor(
    private router: Router,private http: ApiService
    //private authService: AuthService,
  ) { }
  GetChatToken(identity): Observable<any> { 
    return this.http.GET(`${apiPaths.GET_VIDEO_CHAT_TOKEN}/${identity}?Category=conversation`);
  }

  connect(token) {
    console.log('chat connected');
    Twilio.Client.create(token).then( (client: Client) => {
      this.chatClient = client;
      this.chatConnectedEmitter.emit(true);
    }).catch( (err: any) => {
      console.log('error');
      console.log('error: '+err.message);
      this.chatDisconnectedEmitter.emit(true);
      if( err.message.indexOf('token is expired') ) {
        localStorage.removeItem('twackToken');
        //this.router.navigate(['/']);
      }
    });
  }

  getPublicChannels() {
    return this.chatClient.getPublicChannelDescriptors();
  }

  getUserChannels() {
    return this.chatClient.getUserChannelDescriptors();
  }

  getChannel(sid: string): Promise<Channel> {
    return this.chatClient.getChannelBySid(sid);
  }

  async getPrivateChannelByName(uniqueName: string): Promise<Channel> {
    console.log('Channel get started',uniqueName);
    return this.chatClient.getChannelByUniqueName(uniqueName);
   
    
  }

  async getChannelByName(uniqueName: string): Promise<Channel> {
    console.log('Channel get started',uniqueName);
    return this.chatClient.getChannelByUniqueName(uniqueName);
    
    
  }

  async createChannel(friendlyName: string, isPrivate: boolean=false,uniqueName: string) {
    console.log('create channel',friendlyName,uniqueName);
    return this.chatClient.createChannel({friendlyName: friendlyName, isPrivate: isPrivate, uniqueName: uniqueName});
  }

}
