<div class="matModal">
    <div class="modal-header" fxLayout="row" fxLayoutAlign="space-between start">
        <h5 class="modal-title">User Setting</h5>
        <button class="btn p-0 text-white" mat-dialog-close type="button" (click)="onDismiss()"><i
                class="material-icons">close</i></button>
    </div>
</div>
<div class="form">
    <div>
        <form #submitForm="ngForm" (ngSubmit)="submitForm.valid && onSubmit(submitForm)">

            <div class="form-group">
                <mat-form-field style="width: 500px;">
                    <mat-label>Select TimeZone</mat-label>
                    <mat-select class="field-control" placeholder="Select Time Zone" name="TimeZone" #TimeZone="ngModel"
                        [(ngModel)]="formModel.TimeZone" required>
                        <mat-option *ngFor="let data of timeZoneArray" [value]="data.TimeZone">
                            {{data.TimeZoneDescription}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="form-group">
                <mat-form-field style="width: 500px;">
                    <mat-label>Select Date Format</mat-label>
                    <mat-select class="field-control" placeholder="Select Date Format" name="DateFormat"
                        #DateFormat="ngModel" [(ngModel)]="formModel.DateFormat" required>
                        <mat-option *ngFor="let data of dateFormatArray" [value]="data.Value">
                            {{data.DisplayName}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="form-group">
                <mat-form-field style="width: 500px;">
                    <mat-label>Select Time Format</mat-label>
                    <mat-select class="field-control" placeholder="Select Time Format" name="TimeFormat"
                        #TimeFormat="ngModel" [(ngModel)]="formModel.TimeFormat" required>
                        <mat-option *ngFor="let data of timeFormatArray" [value]="data.Value">
                            {{data.DisplayName}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="form-bottom" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
                <button class="btn btn-primary" mat-flat-button type="submit">SAVE</button>
            </div>

        </form>
    </div>
</div>