import { Injectable, EventEmitter, Output } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { JwtService } from 'src/app/services/core/jwt.service';
import { SnackbarService } from '../snackBar/snackbar.service';
import { UserService } from '../user/user.service';
import { refreshToken } from '../../model/refreshToken.model'
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  //public getLoggedInName = new Subject();
  @Output() getLoggedInName: EventEmitter<any> = new EventEmitter();
  @Output() isLogin: EventEmitter<any> = new EventEmitter();
  currentUser = new BehaviorSubject(null);
  refreshToken: refreshToken = new refreshToken();
  private readonly key = environment.headerKey;

  constructor(
    private jwtService: JwtService, private snackBar: SnackbarService, private userService: UserService,
    private router: Router,
  ) {
    if (localStorage.getItem(this.key)) {
      const data = JSON.parse(localStorage.getItem(this.key));
      this.currentUser.next(data);
    }
  }

  // When User login data is save in sessionStorage
  onUserLogin(data, username, isLogin) {
    if (data && data !== undefined) {
      this.getLoggedInName.emit(username);
      this.isLogin.emit(isLogin);
      this.currentUser.next(data);
      this.saveToken(data, true)
    }
  }

  //function to save token
  saveToken(data: any, rememberMe: boolean) {
    if (rememberMe) {
      localStorage.setItem(this.key, JSON.stringify(data));
    }
    else {
      localStorage.setItem(this.key, JSON.stringify(data));
    }
  }

  //function to get token
  getToken() {
    const localToken = window.localStorage['access_token'];
    //console.log('localToken=>', localToken);

    return localToken;
  }

  

  getRecuiterId() {
    const id = this.jwtService.getCurrentUserId();
    return id;
  }

  // remove user from session storage to log user out
  onLogout() {
    this.currentUser.next(null);
    //console.log('localstorage',localStorage);
   // console.log('localstorage', localStorage.length);
    if (localStorage != null || localStorage.length == 0) {
      localStorage.removeItem(this.key);
      //sessionStorage.removeItem(this.key);
    }

    if (document.getElementById('appHome') != null) {
      document.getElementById('appHome').removeAttribute('class');
      document.getElementById('appHome').setAttribute('class', 'wrapper nonlogin');
    }

  }

  // remove local storage 
  removeLocalStoragedata() {
  
   // console.log('localstorage', localStorage.length);
    if (localStorage != null ) {
      localStorage.clear();  
    }

    if (document.getElementById('appHome') != null) {
      document.getElementById('appHome').removeAttribute('class');
      document.getElementById('appHome').setAttribute('class', 'wrapper nonlogin');
    }

  }

  // remove local storage and login page
  removeLocalStoragedataAndRedierctLoginPage() {
  
    //console.log('User session expired and redirected to login');
    this.onLogout();
    this.router.navigate(['/login']);
  }


  isTokenExpired(token?: string): boolean {  
    if (!token) { token = this.getToken(); }
    if (!token) { return true; }

    // const date = this.getTokenExpirationDate(token);
    // if (date === undefined) { return false; }
    // return !(date.valueOf() > new Date().valueOf());
    return this.getTokeStatus();
  }
  getTokenExpirationDate(token: string): Date {
    const decoded = this.jwtService.getDecodedAccessToken(token);

    if (decoded.exp === undefined) return null;

    const date = new Date(0);
    date.setUTCSeconds(decoded.exp);
   // console.log('date=>', date);
    return date;
  }
  getTokeStatus() { 
    const now = Date.now().valueOf() / 1000;
    //console.log('now', now);
    const token = this.jwtService.getToken();
    const decoded = this.jwtService.getDecodedAccessToken(token);
    //
    // var token1 = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiYWRtaW5OYW1lIjoiQWRtaW4iLCJlbWFpbCI6ImluZnJhQHNtYXJ0a2xpY2suY29tIiwicHJvZmlsZUltYWdlIjoicHJvZmlsZWltYWdlX0FkbWluXzFfMTU4ODMyODg4NjYxOS5wbmciLCJpYXQiOjE1OTM3NTMzNTMsImV4cCI6MTU5NjM0NTM1M30.f2BHVh_-UbwoVd1YeFmHnY_77nWpws-RbcsBWywWjdc';

    //   const testdecod=this.jwtService.getDecodedAccessToken(token1)
    //   console.log('========================');

    //   console.log('decoded.exp', testdecod.exp);
    //   const diffValue1 = (testdecod.exp - now);
    //   console.log('diffValue1', diffValue1);
    //   const diffmint1 = diffValue1 / 60;
    //   console.log('diffmint', diffmint1);
    //   console.log('========================');
    //   //
    //console.log('decoded.exp', decoded.exp);
    const diffValue = (decoded.exp - now);
    //console.log('diffValue', diffValue);
    if (diffValue > 0) {
      const diffmint = diffValue / 60;
      //console.log('diffmint', diffmint);
      if (diffmint <= environment.refreshTokenTime) {
        const tokenValue = window.localStorage['access_token'];
        // console.log('localToken=>', tokenValue);
        this.refreshToken.tokenValue = tokenValue;
        //console.log(' this.refreshToken.tokenValue', this.refreshToken.tokenValue);
        //Manu Datta: 20210512, RefreshToken
        this.userService.RefreshToken(this.refreshToken).subscribe(response => {
          try {
            if (response.success == true) {
              localStorage.setItem('islogin', 'true');
              this.jwtService.saveToken(response.data, true);
              const userid = this.jwtService.getCurrentUserId();
              localStorage.setItem('currentuserid', userid);
            }
            else {
              // this.isLoading = false;
              if (response.Message) {
                this.snackBar.openSnackBar(response.Message);
              } else {
                //console.log('some error occured');
                this.snackBar.openSnackBar('some error occured');
                //this.commonService.commonSnakeBar();
              }
            }
          }
          catch (e) {
            this.snackBar.openSnackBar('some error occured');
          }
        });
        // write code here fore refresh the ;
        if (typeof decoded.exp !== 'undefined' && decoded.exp < now) {
         // console.log(`token expired: ${JSON.stringify(decoded)}`)
          return false;
        }
        return true;
      } else {
        return true;
      }
    }
    return false;
  }

}
