import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { NgForm, FormsModule } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Subscription } from 'rxjs';
import { DialogComponent } from 'src/app/components/recruiter/fullfillment-calculator/dialog/dialog.component';
import { JwtService } from 'src/app/services/core/jwt.service';
import { DashboardServiceService } from 'src/app/services/dashboard/dashboard-service.service';
import { LoggingMonitoringService } from 'src/app/services/logging.service';
import { SharedService } from 'src/app/services/shared.service';
import { SnackbarService } from 'src/app/services/snackBar/snackbar.service';
import { MatLegacyPaginatorModule } from '@angular/material/legacy-paginator';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';
import { MatIconModule } from '@angular/material/icon';
import { NgIf, NgFor, DatePipe } from '@angular/common';
import { NgxLoadingModule } from 'ngx-loading';

@Component({
    selector: 'app-preset-filter-popup',
    templateUrl: './preset-filter-popup.component.html',
    styleUrls: ['./preset-filter-popup.component.scss'],
    standalone: true,
    imports: [NgxLoadingModule, FormsModule, NgIf, MatIconModule, NgFor, MatLegacyTooltipModule, MatLegacyPaginatorModule, DatePipe]
})
export class PresetFilterPopupComponent implements OnInit,OnDestroy {
  private subscriptions: Subscription[] = []
  FilterDetails
  constructor(private jwtService: JwtService, @Inject(MAT_DIALOG_DATA) public dialogdata, private sharedService:SharedService, public dialogRef: MatDialogRef<PresetFilterPopupComponent>, private service: DashboardServiceService,private snackBar: SnackbarService,private PresetFilterDiloge:MatDialog,private loggingService: LoggingMonitoringService) { }
 
  SerachOption
  pager = {
    pageIndex: 0,
    limit: 6,
    total: 0,
    pageSizeOptions: [6, 12, 18, 24]
  }
  pageEvent(event) {        
    
      this.pager.pageIndex = event.pageIndex;
      this.pager.limit = event.pageSize;
   
      this.getAllFilter()
     
    
  }
  ngOnInit(): void {
    this.SerachOption=this.dialogdata.event;
    this.getAllFilter();    
  }
  validateSpace(e) {
    this.sharedService.validateBlankSpace(e);
  }
  onPopupClose() {   
    this.dialogRef.close('popClose')   
  } 
  trackByFn(index, item) { 
    return index; 
  }
  PresetFilterSerach
  Loading=false 
  itemSelected=false
  clearFilterSearchField()
  {
    this.PresetFilterSerach="";
    this.getAllFilter();
  }
  validateGroupQueryInput() {
    if (this.PresetFilterSerach?.trim() == "") {
      this.getAllFilter();

    }
  }
  getAllFilter()
  {
    this.Loading=true;
    this.pager.total=0
    const data={SubscriberId:this.jwtService.getSubscriberId(),SearchValue:this.SerachOption,CommonSerach:this.PresetFilterSerach,pageNumber: this.pager.pageIndex + 1,pageSize: this.pager.limit}
    this.subscriptions.push(
    this.service.getAllFilter(data).subscribe(res=>{
      this.FilterDetails=res;   
      this.Loading=false;
      if(this.FilterDetails.length>0)
      {
      this.pager.total = res[0]['TotalCount'];
      }
      else{
        this.pager.total = 0;
      }
        },(err)=>{this.loggingService.logException(err);}))
  }
  SelectedCollectionObject="";
  SelectedFilterName=""
  SelectFilter(data,FilterName)
  { 
    this.itemSelected=true
    this.SelectedFilterName=""
    this.SelectedCollectionObject=""
    this.SelectedFilterName=FilterName
    this.SelectedCollectionObject= data;   
  }
  SubmitSelectedFilter()
  {
    
    let ArrayObject = JSON.parse(this.SelectedCollectionObject); 
    this.dialogRef.close(ArrayObject); 
  }
  clickMethod(item)
  {
      const dialogRef = this.PresetFilterDiloge.open(DialogComponent, {
      width: '30%',
      maxHeight: '90%',
      panelClass: 'modal-matdelete',
      data:{"title":"Delete Filter","message":"Are you sure you want to delete this entry?"} ,   
      disableClose: true     
    });
    dialogRef.afterClosed().subscribe(data => {     
      if(data.event=='Yes')
      {    
       this.DeleteFilter(item)
      }
    })  
  }
  DeleteFilter(item)
  {
    const data={ID:item.ID}
    this.subscriptions.push(
    this.service.DeleteFilter(data).subscribe(res=>{  
      this.snackBar.openSnackBar(res["Message"])
      this.getAllFilter();  
        },(err)=>{this.loggingService.logException(err);}))  
  }
  convertJsonToArray(jsonObj,P)
  {
    let RetValue="";
    let ArrayObject = JSON.parse(jsonObj); 
    if(P=='Days')
    {
       RetValue=ArrayObject['Days']
    }
    else if(P=='Recruiter')
    {    
        ArrayObject['Recruiter']?.forEach((value)=> {
          if(RetValue=="")
          {
            RetValue=value["name"];
          }
          else
          {
            RetValue=RetValue+" , "+value["name"];
          }       
    });     
    }
    else if(P=='Country')
    {    
        ArrayObject['Country']?.forEach((value)=> {
          if(RetValue=="")
          {
            RetValue=value["name"];
          }
          else
          {
            RetValue=RetValue+" , "+value["name"];
          }       
    });     
    }
    else if(P=='skills')
    {   
      
        ArrayObject['skills']?.forEach((value)=> {
          if(RetValue=="")
          {
            RetValue=value["name"];
          }
          else
          {
            RetValue=RetValue+" , "+value["name"];
          }       
    });     
    }
    else if(P=='Recommendation')
    {   
      
        ArrayObject['Recommendation']?.forEach((value)=> {
          if(RetValue=="")
          {
            RetValue=value["name"];
          }
          else
          {
            RetValue=RetValue+" , "+value["name"];
          }       
    });     
    }
    else if(P=='Attribute')
    {   
      
        ArrayObject['Attribute']?.forEach((value)=> {
          if(RetValue=="")
          {
            RetValue=value["name"];
          }
          else
          {
            RetValue=RetValue+","+value["name"];
          }       
    });     
    }
    else if(P=='InterviewType')
    {   
      
        ArrayObject['InterviewType']?.forEach((value)=> {
          if(RetValue=="")
          {
            RetValue=value["DisplayName"];
          }
          else
          {
            RetValue=RetValue+","+value["DisplayName"];
          }       
    });     
    }
    else if(P=='RecommendationType')
    {   
      
        ArrayObject['RecommendationType']?.forEach((value)=> {
          if(RetValue=="")
          {
            RetValue=value["Remark"];
          }
          else
          {
            RetValue=RetValue+","+value["Remark"];
          }       
    });     
    }
    else if(P=='reviewerType')
    {   
      
        ArrayObject['reviewerType']?.forEach((value)=> {
          if(RetValue=="")
          {
            RetValue=value["value"];
          }
          else
          {
            RetValue=RetValue+","+value["value"];
          }       
    });     
    }
    return RetValue;

  }
  ngOnDestroy(): void {
    this.subscriptions.map(s => s.unsubscribe);
  }
}
