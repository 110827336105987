import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { apiPaths } from 'src/app/setting/apiPath';
import { ApiService } from '../core/api.service';
import { EncrDecrService } from '../EncrDecr/encr-decr.service';
import { Observable,throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DashboardServiceService {

    JwtToken: string = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJVc2VySWQiOiIxNjczOWRiZS0wZjg2LTQ1ZjEtYjNlYy1iNzZkMDRmYjRmYTgiLCJVc2VybmFtZSI6InRhbGliIiwiRW1haWwiOiJ0YWxpYjEwMUB5b3BtYWlsLmNvbSIsImh0dHA6Ly9zY2hlbWFzLm1pY3Jvc29mdC5jb20vd3MvMjAwOC8wNi9pZGVudGl0eS9jbGFpbXMvcm9sZSI6ImFkMjNiMzQ5LTVlYTQtNDljMi05YjY1LTUyYjk2YjU1ZDUwZCIsIlJvbGUiOiJTdWJzY3JpYmVyRW1wbG95ZWUiLCJuYmYiOjE1OTc3NDE4MzgsImV4cCI6MTU5Nzc1NjIzOH0.Ge88_53qt9Nc8-v6ze98KBBQ5V_IPlH8G1wA4fKV7g8'
  //token: string = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiYWRtaW5OYW1lIjoiQWRtaW4iLCJlbWFpbCI6ImluZnJhQHNtYXJ0a2xpY2suY29tIiwicHJvZmlsZUltYWdlIjoicHJvZmlsZWltYWdlX0FkbWluXzFfMTU4ODMyODg4NjYxOS5wbmciLCJpYXQiOjE1OTM3NTMzNTMsImV4cCI6MTU5NjM0NTM1M30.f2BHVh_-UbwoVd1YeFmHnY_77nWpws-RbcsBWywWjdc';

  constructor(
    private httpSer: HttpClient,
    private http: ApiService,
    private EncrDecr: EncrDecrService,
    //private env: environment 
  ) { }

  private setHeaders(): HttpHeaders {
    const headersConfig = {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    }
    headersConfig['authenticate'] = this.JwtToken;
    return new HttpHeaders(headersConfig);
  };

  filterSubmit(data: any) {
    return this.http.POST(`${apiPaths.ALL_DASHBOARD}`, data )
  }

  filterGroupBYSubmit(data: any) {
    return this.http.POST(`${apiPaths.GET_INTERVIEW_DETAILS}`, data )
  }

  filterRecuriterDetils(data: any) {
    return this.http.POST(`${apiPaths.DASHBOARD_RECURITER_DETAILS}`, data )
  }

  filterBusinessDetils(data: any) {
    return this.http.POST(`${apiPaths.DASHBOARD_BUSINESS_DETAILS}`, data )
  }

  filterinterviewSubmit(data: any) {
    return this.http.POST(`${apiPaths.ALL_DASHBOARD_INTERVIEW_DETAILS}`, data )
  }
  filterinterviewGraphSubmit(data: any) {
    return this.http.POST(`${apiPaths.DASHBOARD_BAR_GRAPH_DETAILS}`, data )
  }  
  getSkills()
  { 
    return this.http.GET(`${apiPaths.GET_SKILLS}`);
  }
  getRecuriter()
  { 
    return this.http.GET(`${apiPaths.GET_RECURITER}`);
  }

  getAttributes(data: any)
  { 
    return this.http.POST(`${apiPaths.GET_ATTRIBUTES}`,data);
  }
  getRecuriterList(data: any)
  { 
    return this.http.POST(`${apiPaths.GET_RECRUITER_LIST_DETAILS}`,data);
  }
  getinterviwerList(data: any)
  { 
    return this.http.POST(`${apiPaths.GET_INTERVIWER_LIST_DETAILS}`,data);
  }
  filterinterviewbySubscriberIDSubmit(data: any) {
    return this.http.POST(`${apiPaths.INTERVIEW_DETAILS_BY_SUBSCRIBERID}`, data )
  }
  filterinterviewGraphbySubscriberIDSubmit(data: any) {
    return this.http.POST(`${apiPaths.DASHBOARD_BAR_GRAPH}`, data )
  }

  filterSelectedCandidateSubmit(data: any) {
    return this.http.POST(`${apiPaths.DASHBOARD_SELECTED_CANDIDATE}`, data )
  }
  
  filterSelectedCandidatebyRecommandation(data: any) {
    return this.http.POST(`${apiPaths.DASHBOARD_SELECTED_CANDIDATE_BY_RECOMMANDATION}`, data )
  }
  filterDetailsbySBUSubmit(data: any) {
    return this.http.POST(`${apiPaths.GET_INTERVIEW_DETAILS_GROUPBY}`, data )
  }
  filterSubmitByDate(data: any) {
    return this.http.POST(`${apiPaths.ALL_DASHBOARD_BY_DATE}`, data )
  }

  filterInterviewLevelStatus(data: any) {
    return this.http.POST(`${apiPaths.INTERVIEW_LEVEL_CLEAR_STATUS}`, data )
  }


  filterRecommandationAttributeStatus(data: any) {
    return this.http.POST(`${apiPaths.GET_ATTRIBUTE_CANDIDATE_BY_RECOMMANDATION}`, data )
  }

  filterRecommandationAttributeMailer(data: any) {
    return this.http.POST(`${apiPaths.GET_ATTRIBUTE_CANDIDATE_BY_RECOMMANDATION_MAILER}`, data )
  }


  filterSubmitByRecuriter(data: any) {
    return this.http.POST(`${apiPaths.ALL_DASHBOARD_BY_RECURITER_DETAILS}`, data )
  }

  filterSubmitByPanel(data: any) {
    return this.http.POST(`${apiPaths.ALL_DASHBOARD_BY_PANEL_DETAILS}`, data )
  }

  filterSubmitByRecuriterMailer(data: any) {
    return this.http.POST(`${apiPaths.ALL_DASHBOARD_BY_RECURITER_DETAILS_MAILER}`, data )
  }
  getPrimarySkillList(data: any)
  { 
    return this.http.POST(`${apiPaths.GET_PRIMARY_SKILL_LIST}`,data);
  }

  filterSelectedCandidateByRecommandation(data: any) {
    return this.http.POST(`${apiPaths.SELECTED_CANDIDATE_BY_RECOMMANDATION}`, data )
  }
  filterInterviwerDetailsStatus(data: any) {
    return this.http.POST(`${apiPaths.GET_INTERVIWER_DETAILS}`, data )
  }
  filterInterviwerInterviewDetails(data: any) {
    return this.http.POST(`${apiPaths.DASHBOARD_INTERVIWER_INTERVIEW_DETAILS}`, data )
  }
  filterInterviwerInterviewHorizontzlBarDetails(data: any) {
    return this.http.POST(`${apiPaths.DASHBOARD_INTERVIWER_INTERVIEW_STACKED_BAR}`, data )
  }

  getInterviwerSkillDetail(data: any)
  { 
    return this.http.POST(`${apiPaths.GET_INTERVIWER_SKILL_LIST}`,data);
  }

  getInterviwerGroupnameDetail(data: any)
  { 
    return this.http.POST(`${apiPaths.GET_INTERVIWER_GROUP_NAME_LIST}`,data);
  }
  savePresetFilter(data: any)
  { 
    return this.http.POST(`${apiPaths.savePresetFilter}`,data);
  }

  getAllFilter(data: any)
  { 
    return this.http.POST(`${apiPaths.getAllFilter}`,data);
  }
  getAllFilterList(data: any)
  { 
    return this.http.POST(`${apiPaths.GetAllFiltersBySubscriberId}`,data);
  }
  DeleteFilter(data: any)
  {  
    return this.http.POST(`${apiPaths.DeleteFilter}`,data);
  }

  getInterviewTypeList(): Observable<any> {
    return this.http.GET(`${apiPaths.GET_INTERVIEW_TYPE_List}`);
  }
}


