import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders  } from '@angular/common/http'; 
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class IpServiceService {

  constructor(public http:HttpClient) { }

  public getIPAddress()  
  {  
    const ipServiceUrl=`${environment.ipServiceUrl}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      'Accept': 'application/json',
      })
    };
    //const headers = new Headers();
    
    //headers.append('Access-Control-Allow-Origin', '*');
    return this.http.get(ipServiceUrl);  
  }
}
