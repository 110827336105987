import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivate, Router } from '@angular/router';
import { JwtService } from '../services/core/jwt.service';
import { AuthService } from '../services/auth/auth.service';
import { UserService } from '../services/user/user.service';
import { UntypedFormGroup } from '@angular/forms';
import { SnackbarService } from '../services/snackBar/snackbar.service';
//import { refreshToken } from '../model/refreshToken.model'

@Injectable({
  providedIn: 'root'
})

export class AuthGuard implements CanActivate {
  loginForm: UntypedFormGroup;
  constructor(private jwtService: JwtService, private router: Router, private authService: AuthService,
    private userService: UserService, private auth: AuthService, private snackBar: SnackbarService,
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    
      // if (true) {
      //   // Logged in so return true
      //   return true;
      // }
      // write this for testing
      //this.loginForm.set
      if (!this.authService.isTokenExpired()) {
        if (this.authService.getTokeStatus()) {
          // const tokenValue = window.localStorage['access_token'];
          // console.log('localToken=>', tokenValue);
          // this.refreshToken.tokenValue = tokenValue;
          // console.log(' this.refreshToken.tokenValue', this.refreshToken.tokenValue);
          // this.userService.RefreshToken(this.refreshToken).subscribe(response => {
          //   try {
          //     if (response.Success == true) {
          //       localStorage.setItem('islogin', 'true');
          //       this.jwtService.saveToken(response.Message, false);
          //       const userid = this.jwtService.getCurrentUserId();
          //       localStorage.setItem('currentuserid', userid);
          //     }
          //     else {
          //       // this.isLoading = false;
          //       if (response.Message) {
          //         this.snackBar.openSnackBar(response.Message);
          //       } else {
          //         console.log('some error occured');
          //         this.snackBar.openSnackBar('some error occured');
          //         //this.commonService.commonSnakeBar();
          //       }
          //     }
          //   }
          //   catch (e) {
          //     this.snackBar.openSnackBar('some error occured');
          //   }
          // });
         // console.log('this.authService.isTokenExpired()', this.authService.isTokenExpired());
          //Manu Datta: 20210420, Save Logs
         
          
          return true;
  
        }else{
          //Manu Datta: 20210428, seesion oute after token expired
          this.jwtService.deleteToken();
          this.auth.onLogout()
          this.router.navigate(['/login']);
          return false;
  
        }
        //this.loginForm.controls['tokenValue'].setValue(tokenValue);
       
  
      }
  
  
      if (this.jwtService.getToken()) {
        //console.log('login When auth gard');
        return true;
      }
  
      
      console.log('not login');
      // Not logged in so redirect to login page with the return url
      this.router.navigate(['/login']);
      // this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
      return false;
    }

}
