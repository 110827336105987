import { Component, OnInit, Output, EventEmitter, HostListener, ViewChild, TemplateRef } from '@angular/core';
import { HeaderNav } from './headerNav';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { JwtService } from 'src/app/services/core/jwt.service';
import { Router, RouterLinkActive, RouterLink } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ResetPasswordComponent } from '../../recruiter/home/reset-password/reset-password.component';
import { SharedService } from 'src/app/services/shared.service';
import { share } from 'rxjs/operators';
import { DataService } from 'src/app/services/DataTravel/data.service';
import { MatLegacyTabsModule } from '@angular/material/legacy-tabs';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { MatLegacyOptionModule } from '@angular/material/legacy-core';
import { MatLegacySelectModule } from '@angular/material/legacy-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyMenuModule } from '@angular/material/legacy-menu';
import { LogoComponent } from './logo/logo.component';
import { NgIf, NgFor, NgClass } from '@angular/common';
import { UserConfigurationComponent } from '../../recruiter/home/user-configuration/user-configuration.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  standalone: true,
  imports: [NgIf, LogoComponent, NgFor, RouterLinkActive, RouterLink, MatLegacyMenuModule, FormsModule, ReactiveFormsModule, MatLegacySelectModule, MatLegacyOptionModule, NgClass, ExtendedModule, MatLegacyTabsModule]
})
export class HeaderComponent implements OnInit {
  //@Output() isLogin: EventEmitter<any> = new EventEmitter();
  headerNav: Array<any> = HeaderNav;
  @ViewChild('responsiveMenu', { static: true }) responsiveMenu: TemplateRef<any>;
  isLogout: boolean = false;
  isLogin: boolean = false;
  isUser: boolean = false;
  islstopen: boolean = false;
  userName: string = 'User Name';
  hideMenu: boolean = false;
  Img_Path: any = '/assets/images/user-image.png';

  constructor(
    private dialog: MatDialog,
    private bottomSheet: MatBottomSheet,
    private JwtToken: JwtService,
    private router: Router,
    private auth: AuthService,
    private sharedService: SharedService,
    private dataService: DataService
  ) {

  }

  openResponsiveMenu(): void {
    this.bottomSheet.open(this.responsiveMenu);
  }
  closeReponsiveMenu(): void {
    this.bottomSheet.dismiss()
  }

  OpenlfMenu() { this.islstopen = !this.islstopen }

  ngOnInit() {
    this.Img_Path = this.JwtToken.getRecruiterProfileImgPath();
    if (this.Img_Path == undefined) {
      this.Img_Path = null;
    }
    //console.log('this.router.url',this.router.url);
    this.sharedService.messageSource.subscribe(message => {
      this.hideMenu = message;
    });
    //console.log('main menu status',this.hideMenu);
    //console.log('header'+this.isLogin);
    this.auth.getLoggedInName.subscribe(name =>
      this.updateUserName(name)
    );
    this.auth.isLogin.subscribe(name =>
      this.isLogin = name
    );
    this.auth.currentUser.subscribe(data => {
      //console.log(this.hideMenu);
      if (data && data !== null) {
        document.getElementById('appHome').removeAttribute('class');
        if (this.hideMenu) {
          // console.log('hide');
          document.getElementById('appHome').setAttribute('class', 'wrapper nonlogin');
          this.isUser = false
        }
        else {
          //console.log('show');
          document.getElementById('appHome').setAttribute('class', 'wrapper');
          //console.log('subcribe data') 
          this.isUser = true
        }



        //this.isLogin.emit(true);
        //Manu Datta: 20200909, Display user full name comes from veridial token instead of userName
        // this.userName=this.JwtToken.getUserName();
        this.Img_Path = this.JwtToken.getRecruiterProfileImgPath();
        if (this.Img_Path == undefined) {
          this.Img_Path = null;
        }
        this.userName = this.JwtToken.getUserFullName();
        //End Manu Datta: 20200909, Display user full name comes from veridial token instead of userName
        //console.log('username',this.userName);

      }
      else {
        document.getElementById('appHome').removeAttribute('class');
        document.getElementById('appHome').setAttribute('class', 'wrapper nonlogin');
        this.isUser = false
      }
    })
  }

  private updateUserName(name: string): void {
    this.userName = name;
  }

  logout() {
    this.dataService.setInterviewerId(0);
    this.JwtToken.deleteToken();
    this.auth.onLogout()
    this.router.navigate(['/login']);
  }
  resetPassword() {
    this.dialog.open(ResetPasswordComponent, {
      width: '550px',
      data: '',
    });

  }

  setUserConfiguration() {
    this.dialog.open(UserConfigurationComponent, {
      width: '550px',
      data: '',
    });

  }
}
