import { Component, OnInit, Inject } from '@angular/core';
import { MAT_LEGACY_SNACK_BAR_DATA as MAT_SNACK_BAR_DATA, MatLegacySnackBarRef as MatSnackBarRef } from '@angular/material/legacy-snack-bar';

@Component({
    selector: 'app-snack-bar',
    templateUrl: './snack-bar.html',
    styleUrls: ['./snack-bar.scss'],
    standalone: true
})
export class SnackBarComponent implements OnInit {

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: any,
    private snackRef: MatSnackBarRef<SnackBarComponent>
  ) { }

  onClose() {
    this.snackRef.closeWithAction()
  }

  ngOnInit() {
  }

}
