import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { DialogData } from 'src/app/components/common/img-cropper/img-cropper.component';
import { SlicePipe } from '@angular/common';

@Component({
    selector: 'app-dialog',
    templateUrl: './dialog.component.html',
    styleUrls: ['./dialog.component.scss'],
    standalone: true,
    imports: [SlicePipe]
})
export class DialogComponent implements OnInit {
  title: string;
  message: string;
  constructor( public dialogRef: MatDialogRef<DialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogdata,) { }

  ngOnInit(): void {
    this.title=this.dialogdata.title
    this.message=this.dialogdata.message
  }
 
  decline()
  {
    this.dialogRef.close({event:'No'});
  }
  accept()
  {
    this.dialogRef.close({event:'Yes'});
  }
  dismiss()
  {
    this.dialogRef.close({event:'Cancel'});   
  }
}
