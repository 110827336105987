import { Injectable } from '@angular/core';
import * as jwt_decode from "jwt-decode";


@Injectable({
  providedIn: 'root'  // <- ADD THIS
})
export class JwtService {
  token_name = 'access_token';
  user_Role = 'Role';
  user_id = 'UserId';
  user_email = 'Email';
  user_name = 'Username';
  user_full_name = 'Name'; //Manu Datta: 20200909, user full name comes from veridial token
  subscriber_id = 'CreatedBy'; //Suman Kumar: 20200918, parent id for recruiter comes from veridial token
  Profile_ImagePath = 'ProfileImagePath';
  company_id = "SubscriberId";
  User_TimeZone = 'TimeZone';
  User_DateFormat = 'UserDateFormat';
  User_TimeFormat = 'TimeFormat';
  User_AllowedSubscribers='AllowedSubscribers';
  User_SSOLoginEnabled='EnableSSOLogin';
  // ApplicationDateFormat = this.getUserDateFormat();
  // ApplicationTimeFormat = this.getUserTimeFormat();
  // ApplicationTimeZone = this.getUserTimeZone();
  //ApplicationDateTimeFormat = this.getUserDateFormat() + ' '+ this.getUserTimeFormat();
  constructor() { }

  getToken(): string {
    const localToken = window.localStorage[this.token_name];

    //console.log('localToken',localToken);
    //const sessionToken = window.localStorage[this.token_name];
    const sessionToken = null;
    if (localToken && localToken != '' && localToken != null && localToken != undefined) {
      return localToken;
    }
    else if (sessionToken && sessionToken != '' && sessionToken != null && sessionToken != undefined && sessionToken != 'undefined') {
      //console.log(sessionToken);
      return sessionToken;
    }
    else {
      //console.log('empty token');
      if (document.getElementById('appHome') != null) {
        document.getElementById('appHome').removeAttribute('class');
        document.getElementById('appHome').setAttribute('class', 'wrapper nonlogin');
      }
      return '';
    }
    // console.log('get token end');
  }

  saveToken(data: any, rememberMe: boolean) {
    //console.log(data);
    if (rememberMe) {
      window.localStorage[this.token_name] = data.access_token;
    }
    else {
      window.localStorage[this.token_name] = data;
    }
  }

  deleteToken() {
    localStorage.clear();
    sessionStorage.clear();
  }

  getUserRole(): string {
    var decode = this.getDecodedAccessToken(this.getToken())
    // console.log('decode',decode);
    return decode[this.user_Role];
  }

  getCurrentUserId(): string {
    var decode = this.getDecodedAccessToken(this.getToken())
    return decode[this.user_id];
  }

  getUserEmail(): string {
    var decode = this.getDecodedAccessToken(this.getToken())
    return decode[this.user_email];
  }

  getUserName(): string {
    var decode = this.getDecodedAccessToken(this.getToken())
    return decode[this.user_name];
  }



  getDecodedAccessToken(token: string): any {
    try {
      return jwt_decode(token);
    }
    catch (Error) {
      return null;
    }
  }
  //Manu Datta: 20200909, Get user full name from token
  getUserFullName(): string {
    var decode = this.getDecodedAccessToken(this.getToken())
    return decode[this.user_full_name];
  }

  //Suman Kumar: 20200909, Get parent id for recruiter from token
  getSubscriberId(): string {
    var decode = this.getDecodedAccessToken(this.getToken())
    return decode[this.subscriber_id];

  }
  getCompanyId(): string {
    var decode = this.getDecodedAccessToken(this.getToken())
    return decode[this.company_id];

  }

  getRecruiterProfileImgPath(): string {

    var decode = this.getDecodedAccessToken(this.getToken())
    if (decode != null) {
      var data = decode[this.Profile_ImagePath];
      return data;
    }
  }

  getUserTimeZone(): string {
    var decode = this.getDecodedAccessToken(this.getToken())
    return decode[this.User_TimeZone];
  }

  getUserDateFormat(): string {
    var decode = this.getDecodedAccessToken(this.getToken());
    return decode[this.User_DateFormat];
  }

  getUserTimeFormat(): string {
    var decode = this.getDecodedAccessToken(this.getToken())
    return decode[this.User_TimeFormat];
  }

  getMaterialDateFormat() {
    var decode = this.getDecodedAccessToken(this.getToken());
    var format = decode[this.User_DateFormat];
    if (format === 'MM/dd/yyyy') {
      return 'en-US';
    }
    else if (format === 'dd/MM/yyyy') {
      return 'en-GB';
    }
    return 'en-US';

  }

  getUserDateTimeFormat(): string {    
    return (this.getUserDateFormat() + ' ' + this.getUserTimeFormat());
  }

  getAllowedSuscribers(): string {
    var decode = this.getDecodedAccessToken(this.getToken());
    return decode[this.User_AllowedSubscribers];
  }

  getSSOLoginEnabled(): string {
    var decode = this.getDecodedAccessToken(this.getToken());
    return decode[this.User_SSOLoginEnabled];
  }
  
}