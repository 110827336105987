export const environment = {
  production: false,
  //API_PATH: 'https://api.veriklick.in/api/',
  //API_PATH_VDIAL: 'https://veridialapi.veriklick.in/api/',
   API_PATH: 'http://localhost:63925/api/',
   API_PATH_VDIAL: 'http://localhost:5000/api/',
   API_PATH_VKService: 'https://services.veriklick.in/',
   API_PATH_VKTPGetJobDetailsService: 'https://workflow.veriklick.in/api/v1/cron-workflows/goservices/sparkly-octopus',
  //API_PATH: 'https://api-v2.veriklick.in/api/',
  API_PATH_VERISOURCE: 'https://verisourceapi-staging.veriklick.in/api/',
  Key: 'TemplateApiSecurityKeyToGenerateTheJavascriptWebToken',
  //Key: 'TemplateApiSecurityKeyToGenerateTheJavascriptWebTokenForSmartKlickApplication',
  CandidateProfileDir:'/Content/Candidates/',
  //ROOT_PATH:'https://api-v2.veriklick.in/',
  MSTeamsCandidateurl:'http://localhost:4203',
  ROOT_PATH: 'http://localhost:63925/',
  //ROOT_PATH:'https://api.veriklick.in/',
  CurrentTimeZone:'America/New_York',
  // CandidateProfileDir: '/Content/Candidates/',
  // ROOT_PATH: 'https://api.veriklick.in/',
  // CurrentTimeZone: 'America/New_York',
  videointroductionenabled: true,
  videointroductiontime: 31,
  MaxInterviewUsersAllowed: 7,
  MaxInterviewerAddAllowed:7,
  headerKey: 'headerkey',
  Token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiYWRtaW5OYW1lIjoiQWRtaW4iLCJlbWFpbCI6ImluZnJhQHNtYXJ0a2xpY2suY29tIiwicHJvZmlsZUltYWdlIjoicHJvZmlsZWltYWdlX0FkbWluXzFfMTU4ODMyODg4NjYxOS5wbmciLCJpYXQiOjE1OTM3NTMzNTMsImV4cCI6MTU5NjM0NTM1M30.f2BHVh_-UbwoVd1YeFmHnY_77nWpws-RbcsBWywWjdc',
   UI_PATH_VDIAL:'http://veridial.veriklick.in/',
   UI_PATH_Diagnosis:'https://diagnosis.veriklick.in',
  //UI_PATH_VDIAL:'http://veridial.veriklick.in/',


  CurrentYear:new Date().getFullYear(),
  PusherAppKey:'26c6f898b0434b19f4ad',
  PusherCluster:'us2',
  PusherChannelName:'UpdateGrid',
  FaceBioPusherChannelName:'FaceBioUpdateGrid',
  PusherEventName:'refreshRow',
  FaceBioPusherEventName:'refreshRowFaceBio',

  VideoStartStopPusherChannelName:'VideoStartStopUpdateGrid',
  VideoStartStopPusherEventName:'VideoStartStoprefreshRow',

  ParticipantPusherChannelName:'ParticipantUpdateGrid',
  ParticipantPusherEvent:'ParticipantrefreshRow',

  // PusherAppKey:'1ef9111636fc70ac073b',
  // PusherCluster:'us2',
  // PusherChannelName:'UpdateGrid',
  // PusherEventName:'refreshRow',
  // FaceBioPusherEventName:'refreshRowFaceBio',

  VideoRecordPusherEventName:'refreshStartStopVideoRecordStatus',
  EncryptKey: 'Synk@1234', //Manu Datta: 20210410, Encryption
  refreshTokenTime: 5,  //Manu Datta: 20210512, RefreshToken
  CandidateBulkUploadLimit:100,
  appInsights: {
    instrumentationKey: '06d920f9',
    disableFetchTracking: false,
    enableCorsCorrelation: true,
    enableRequestHeaderTracking: true,
    enableResponseHeaderTracking: true,
    /*correlationHeaderExcludedDomains: ['myapp.azurewebsites.net', '*.queue.core.windows.net']
     ...Other Configuration Options... */
  },
  EnableLog:false,
  EnableVideoSessionLog:true,
  EnableHomepageLog:true,
  EnableProfileverificationLog:true,
  EnableScorecardLog:true,
  EnableProfileCreationLog:true,
  EnableTechScreenTestLog:true,
  EnableTechScreenReportLog:true,
  VideoScreenSkillLengthAllowed:10,
  VideoSessionTimeInSecondsForEarlierJoining:7200,

  CompilerHash:'57d985b9f6738e0f6c2407192e3a03bb',
  CompilerTheme:'dark',
  CandidateCodeAttemptLimit:5,
  EnableUiLogger:false,
  DatepickerdefaultDate:30,
  MSTeamsclientId:'b0cb9951-0e55-4763-99e4-6c7496e746eb',
  MSTeamsauthority:'https://login.microsoftonline.com/7896c5aa-71b4-47c3-98d4-e707bdd7462f',
  MSTeamsredirectUri:'https://ui2.veriklick.in/profile-verification',
  MSTeamspostLogoutRedirectUri:'https://ui2.veriklick.in/profile-verification',
  appDomain:"veriklick.in",
  cookiesExpiryDays:"365",
  ipServiceUrl:"https://api.ipify.org/?format=json",
  AppType:"38A00A05-80EF-4C1C-B402-FA4B063990E7",
  AzureAd:{
    Instance:"https://login.microsoftonline.com/",
    client_id:"1bb710ab-ced5-4f0a-968a-bfbe7654e958",
    TenantId:"organizations",
    response_type:'id_token',
    redirect_uri:'https://veridialapi.veriklick.com/api/v1/Authentication/SSO',
    response_mode:'form_post',
    scope:'openid email',
    nonce:112233
  },

  SSO:true, // Set this to true if you want to enable SSO. Use it for production for clients that have MS Teams integrated
  SendVKScoreCardPDFToBH:true,
  SurvaleApiCall:false,
  IsQuestionnaireReady:true,
  IsInnova:false,
  BHCandidateImportNow:true,
  JobCreatedBy:'gaurav+recruiter5@synkriom.com',
  SingleSignonEnable:true, // Set this to true if you want to enable SSO button. Use it for production for clients that have MS Teams integrated
  JobListRefreshBtnEnable:true,
  VideoAnswerMiliSeconds:2
};/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
