<div class="relative">
    
    <div class="box-title d-flex align-items-center mb-3"> 
        <h2>Add/Update Filter ({{SerachOption}})</h2>       
        <a (click)="onPopupClose()" class="close float-none ml-auto"><i class="material-icons">close</i></a>
    </div>
    <div class="form-radio-group mb-2">
    <mat-radio-group [(ngModel)]="selectedOption">
        <mat-radio-button *ngFor="let item of RadioSelectionList" class="mr-3" [value]="item.value" [checked]="item.checked" color="warn">
          {{item.name}}
        </mat-radio-button>
       </mat-radio-group>
    </div>
    <form #submitForm="ngForm" (ngSubmit)="submitForm.valid && onSubmit(submitForm)" class="pt-0 mt-0">
        <div class="modal-scroll" style="max-height: calc(100vh - 66px - 108px);">         
        <div class="groupname-field" *ngIf="selectedOption=='Add'">
            <mat-form-field class="mat-inputfield" appearance="standard">
                <mat-label>Enter Filter Name</mat-label>      
                    <input matInput type="text" name="Filter_Name" [(ngModel)]="Model.Filter_Name"  
                    pattern="^(?!\s*$).+" class="field-control" #Filter_Name="ngModel" placeholder="Enter filter Name..." 
                    required maxlength="50" (keypress)="removeSpaces($event)" (keypress)="omit_validate_specialchar($event)"> 
            </mat-form-field>  
            <div class="errorMsg" *ngIf="submitForm.submitted && Filter_Name?.invalid">
                <span *ngIf="Filter_Name.errors.required">Filter name is required</span>
                <span *ngIf="Filter_Name.errors.pattern">Filter name is not valid</span>
            </div>         
        </div>
        <div class="groupname-field" *ngIf="selectedOption=='Update'">
            <mat-form-field mat-form-field class="mat-inputfield" appearance="standard">
            <mat-label>Select Filter Name</mat-label>
                <input id="Filter_Name" class="field-control" (ngModelChange)="searchFilterList($event)" [matAutocomplete]="pGroupauto" placeholder="Select Filter Name" matInput type="text" name="Filter_Name" #Filter_Name="ngModel" 
        [(ngModel)]="Model.Filter_Name"  (keypress)="validateSpace($event)" autocomplete="off" required> 
        <mat-autocomplete #pGroupauto="matAutocomplete">
           <mat-option [value]="data.FilterName" *ngFor="let data of FilterListBySubscriber">
            {{data.FilterName}}
           </mat-option>
        </mat-autocomplete> 
         </mat-form-field> 
        
        <div class="errorMsg" *ngIf="submitForm.submitted && Filter_Name?.invalid">
            <span *ngIf="Filter_Name.errors.required">Filter name is required</span>
            <span *ngIf="Filter_Name.errors.pattern">Filter name is not valid</span>
        </div>
        </div>
       
      
        <div class="table-responsive mt-3">
            <table  class="table table-secondary lh-base">   
               
                <tr *ngIf="SerachOption=='Recommendation'"><th width="110">Recommendation</th><td>{{convertJsonToArray(FilterObject,'Recommendation')}}</td></tr>         
                <tr *ngIf="SerachOption!='Ageing'"><th width="110">Recruiter</th><td>{{convertJsonToArray(FilterObject,'Recruiter') }}</td></tr>
                <tr ><th>Country</th><td>{{convertJsonToArray(FilterObject,'Country')}}</td></tr>
                <tr *ngIf="SerachOption!='Verification' && SerachOption!='candidateConsidered' && SerachOption!='candidateInterview'"><th>skills</th><td>{{convertJsonToArray(FilterObject,'skills')}}</td></tr>
                <tr *ngIf="SerachOption=='candidateConsidered' || SerachOption=='candidateInterview'"><th>Attribute</th><td>{{convertJsonToArray(FilterObject,'Attribute')}}</td></tr>
                <tr *ngIf="SerachOption=='candidateConsidered' || SerachOption=='candidateInterview'"><th>interviewType</th><td>{{convertJsonToArray(FilterObject,'InterviewType')}}</td></tr>
                <tr *ngIf="SerachOption=='candidateConsidered' || SerachOption=='candidateInterview'"><th>Recommendation</th><td>{{convertJsonToArray(FilterObject,'RecommendationType')}}</td></tr>
                <tr *ngIf="SerachOption=='candidateConsidered' || SerachOption=='candidateInterview'"><th>Reviewer</th><td>{{convertJsonToArray(FilterObject,'reviewerType')}}</td></tr>
                <tr><th>Time Period</th><td>Today - {{ FilterList?.Days}} Days</td></tr>


            </table>       
        </div>
    </div>
        <div class="text-center mt-3">
        <button type="submit" class="btn btn-primary btn btn-primary btn-md px-5">Save</button></div>
    </form>
</div>