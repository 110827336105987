
import { Injectable, Output, EventEmitter } from '@angular/core';
import { ApiService } from './core/api.service';
import { apiPaths } from '../setting/apiPath';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { throws } from 'assert';

@Injectable({
  providedIn: 'root'
})
export class VideoConferenceService {
  private videoRecordStatusObserver = new Subject();
  public videoStopClicked=this.videoRecordStatusObserver.asObservable();
  ////private messageSource = new BehaviorSubject('');
  //currentAction = this.messageSource.asObservable();
  //@Output() setAddParticipantEvent: EventEmitter<any> = new EventEmitter();
  addNewParticipiantStatus:boolean=false;
  currentAction: string = '';
  constructor(private http: ApiService) { }

  public updateAction(status:boolean) {
    this.videoRecordStatusObserver.next(status);
} 
  changeData(message: string) {
    this.currentAction = message;
  }
  GetVideoAccessDetails(accessCode): Observable<any> {

    return this.http.GET(`${apiPaths.GET_VIDEO_ACCESS_DETAILS}/${accessCode}`);
  }

  GetVideoAccessDetail(emailacceptanceModel): Observable<any> {

    return this.http.POST(`${apiPaths.GET_VIDEO_ACCESS_DETAIL}`,emailacceptanceModel);
  }

  GetInterviewerUserDetails(accessCode): Observable<any> {

    return this.http.GET(`${apiPaths.Get_Interviewer_User_Details}/${accessCode}`);
  }

  GetOwnScreenShareStatus(accessCode): Observable<any> {

    return this.http.GET(`${apiPaths.GetOwnScreenShareStatus}/${accessCode}`);
  }
  
  GetInterviewTotalInterviewerCount(accessCode): Observable<any> {

    return this.http.GET(`${apiPaths.GetInterviewTotalInterviewerCount}/${accessCode}`);
  }

  GetInterviewMeetingPassword(accessCode): Observable<any> {

    return this.http.GET(`${apiPaths.GetInterviewMeetingPassword}/${accessCode}`);
  }
  

  RoomSpeakerMuteUnmute(roomdeatil): Observable<any> {

    return this.http.POST(apiPaths.RoomSpeakerMuteUnmute , roomdeatil);
  }

  SaveRoomStatus(data): Observable<any> {

    return this.http.POST(apiPaths.SaveRoomStatus, data);
  }

  UpdateInterviewerAllowMuteStatus(data): Observable<any> {

    return this.http.POST(apiPaths.UpdateInterviewAllowMuteStatus, data);
  }

  UpdateInterviewerShareScreenStatus(data): Observable<any> {

    return this.http.POST(apiPaths.VIDEO_SCREEN_INTERVIEWUsersScreenShareStatus_UPDATE, data);
  }

  SaveParticipantStatus(data): Observable<any> {

    return this.http.POST(apiPaths.SaveRoomParticipantStatus, data);
  }

  //Save coder pad question 
  SaveCoderpadQuestion(data): Observable<any> {

    return this.http.POST(apiPaths.SaveCoderPadQuestion, data);
  }

  //Get coder pad question list
  GetCoderPadQuestions(data):Observable<any>
  {
    return this.http.POST(apiPaths.GetCoderPadQuestions, data);
  }

  GetVideoConferencetoken(accessCode): Observable<any> {
    return this.http.GET(`${apiPaths.GET_VIDEO_CONF_TOKEN}/${accessCode}`);
  }

  CheckInterviewUserExistWIthEmail(data): Observable<any> {
    return this.http.POST(apiPaths.CHECK_INTERVIEWUSER_EXISTWITHEMAIL,data);
  }

  CheckInterviewUserExistWIthPhone(data): Observable<any> {
    return this.http.POST(apiPaths.CHECK_INTERVIEWUSER_EXISTWITHPHONE,data);
  }

  OnAddParticipantClick(event:boolean)
  {
    //this.setAddParticipantEvent.emit(event);
  }

  UpdateInterviewRecordingStatus(data): Observable<any> {

    return this.http.POST(apiPaths.UPDATE_INTERVIEW_RECORDING_STATUS, data);
  }
  UpdateCandidateRecordingStatus(data): Observable<any> {

    return this.http.POST(apiPaths.UPDATE_CANDIDATE_RECORDING_STATUS, data);
  }
  GetVideoInterviewDetails(interviewId): Observable<any> {
    return this.http.GET(`${apiPaths.GET_SAVE_VIDEO_SCREEN}/${interviewId}`);
  }
  GetInterviewerUserVerifyAssesmentDetails(data): Observable<any> {

    //return this.http.GET(`${apiPaths.Get_Interviewer_assesment_verify_Details}/${data}`);
    return this.http.POST(`${apiPaths.Get_Interviewer_assesment_verify_Details}`, data);
  }
   //Get coder pad question list
   GetCoderPadQuestionByQuestionId(data):Observable<any>
   {
     return this.http.POST(apiPaths.GetCoderPadQuestionByQuestionId, data);
   }

    //Get candidate screen share status
    GetCandidateScreenShareStatus(accessCode): Observable<any> {

    return this.http.GET(`${apiPaths.GetCandidateScreenShareStatus}/${accessCode}`);
  }

  //Get coder pad question attempt count
  GetCandidateCodePadAttemptCountByQuestionId(data):Observable<any>
  {
    return this.http.POST(apiPaths.GetCandidateCodePadAttemptCountByQuestionId, data);
  }

  //Save jobseeker optout data to veridial DB
  SaveJobseekerOptoutdataToVeridial(data):Observable<any>
  {
    return this.http.POSTVDIAL(apiPaths.SaveJobseekerOptoutdataToVeridial, data);
  }

  addCookiesConsentDataWithHistory(Model): Observable<any> {
    return this.http.POSTVDIAL<any>(apiPaths.AddCookiesConsentDataWithHistory, Model);
  }

  AddRevokeCookiesConsentDataWithHistory(Model): Observable<any> {
    return this.http.POSTVDIAL<any>(apiPaths.AddRevokeCookiesConsentDataWithHistory, Model);
  }
  SurvaleVirtusaAPI(VAC): Observable<any> {
    return this.http.POST(`${apiPaths.SurvaleVirtusaAPI}`,VAC);
  }

  GetInterviewInBetweenRuleCheck(data):Observable<any>
  {
    return this.http.POST(apiPaths.InbetweenInterviewCheck, data);
  }

  GetVRoomConnectedParticipantDetail(accessCode): Observable<any> {
    return this.http.GET(`${apiPaths.GET_VIDEOROOM_CONN_PARTICIPANT}/${accessCode}`);
  }
}