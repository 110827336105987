import { NgClass, NgFor, NgIf } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component } from '@angular/core';
import { ExtendedModule, FlexLayoutModule, FlexModule } from '@angular/flex-layout';
import { FormsModule, NgForm, ReactiveFormsModule } from '@angular/forms';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatLegacyInputModule } from '@angular/material/legacy-input';
import { ActivatedRoute, RouterLink, RouterLinkActive, Router } from '@angular/router';
import { DataService } from 'src/app/services/DataTravel/data.service';
import { EncrDecrService } from 'src/app/services/EncrDecr/encr-decr.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { JwtService } from 'src/app/services/core/jwt.service';
import { SharedService } from 'src/app/services/shared.service';
import { SnackbarService } from 'src/app/services/snackBar/snackbar.service';
import { UserService } from 'src/app/services/user/user.service';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogModule as MatDialogModule, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatFormField } from '@angular/material/form-field';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacySelectModule } from '@angular/material/legacy-select';

@Component({
  selector: 'app-user-configuration',
  templateUrl: './user-configuration.component.html',
  styleUrls: ['./user-configuration.component.scss'],
  standalone: true,
  imports: [RouterLinkActive, RouterLink, FormsModule, FlexModule, NgClass, ExtendedModule, NgIf, NgFor, MatLegacyInputModule,MatLegacyFormFieldModule,MatLegacySelectModule]    
})
export class UserConfigurationComponent {
  timeZoneArray: Array<any> = [];
  //ddlDataArray: Array<any> = [];
  dateFormatArray: Array<any> = [];
  timeFormatArray: Array<any> = [];

  formModel = {
    TimeZone: '',
    DateFormat: '',
    TimeFormat: ''
  }

  constructor(private dialogRef: MatDialogRef<UserConfigurationComponent>,
    private snackbar: SnackbarService,
    private sharedService: SharedService,
    private router: Router,
    private route: ActivatedRoute,
    private http: HttpClient,
    private userService: UserService,
    private jwtService: JwtService,
    private EncrDecr: EncrDecrService,
    private dialog: MatDialog,
    private bottomSheet: MatBottomSheet,
    private cdr: ChangeDetectorRef,
    //private JwtToken: JwtService,
    //private router: Router,
    private auth: AuthService,
    //private sharedService: SharedService,
    private dataService: DataService
  ) {

  }

  ngOnInit(): void {
    this.bindTimeZoneList();
    this.bindDropDownData();


    this.formModel.TimeZone = this.jwtService.getUserTimeZone();
    this.formModel.DateFormat = this.jwtService.getUserDateFormat();
    this.formModel.TimeFormat = this.jwtService.getUserTimeFormat();
  }

  onSubmit(form: NgForm) {
    // console.log('form values', form);
    // console.log('this.formModel.TimeZone: ', this.formModel.TimeZone);
    // console.log('this.formModel.DateFormat: ', this.formModel.DateFormat);
    // console.log('this.formModel.TimeFormat: ', this.formModel.TimeFormat);
    if (form.valid) {
      //this.loading = true;
      let params = {

        UserId: this.jwtService.getCurrentUserId(),
        TimeZone: this.formModel.TimeZone,
        DateFormat: this.formModel.DateFormat,
        TimeFormat: this.formModel.TimeFormat
      }

      this.userService.updateUserConfig(params).subscribe(response => {


        console.log(response);
        try {
          if (response.success == true) {

            //this.loading = false;
            if (response.message) {
              // var refreshedToken = this.userService.RefreshToken(null);
              // console.log('refreshedToken: ',refreshedToken);
              //this.userService.updateCalendarLocale(this.formModel.DateFormat);
              //this.cdr.detectChanges(); 
              this.onDismiss();
              this.snackbar.openSnackBar(response.message);
              this.logout();
            }
          }
          else {
            //this.loading = false;
            if (response.message) {
              this.snackbar.openSnackBar(response.message);
            } else {
              console.log('some error occured');

            }
          }
        }
        catch (e) {
          //this.loading = false;
          this.snackbar.openSnackBar('some error occured');
        }

      }, (err) => {
        //this.loading = false;
        console.log(err);
        let response = err;

        if (response.Message) {
          this.snackbar.openSnackBar(response.Message);
        } else {
          this.snackbar.openSnackBar('some error occured');
        }
      }, () => {
        //this.loading = false;

      })
    } else {
      return false;
    }
  }

  onDismiss(): void {
    // Close the dialog, return false
    this.dialogRef.close(false);
  }

  logout() {
    this.dataService.setInterviewerId(0);
    this.jwtService.deleteToken();
    this.auth.onLogout()
    this.router.navigate(['/login']);
  }

  bindTimeZoneList() {
    this.sharedService.getTimeZones().subscribe((res: Array<any>) => {
      this.timeZoneArray = res;
    });
  }

  bindDropDownData() {
    this.userService.getLookupData().subscribe((res: Array<any>) => {
      console.log('getLookupData: ', res);
      if (res != null && res != undefined) {
        console.log('DF: ', res.filter((d) => d.LuType === "DF"));
        console.log('TF: ', res.filter((d) => d.LuType === "TF"));
        this.dateFormatArray = res.filter((d) => d.LuType === "DF");
        this.timeFormatArray = res.filter((d) => d.LuType === "TF");
      }
      else {
        this.timeFormatArray = [
          { DisplayText: '12 hour (AM/PM)', Value: 'hh:mm a' },
          { DisplayText: '24 hour', Value: 'HH:mm' }
        ];
        this.dateFormatArray = [
          { DisplayText: 'MM/DD/YYYY', Value: 'MM/dd/yyyy' },
          { DisplayText: 'DD/MM/YYYY', Value: 'dd/MM/yyyy' }
        ];
      }
      //this.ddlDataArray = res;

    });
  }
}