<div class="relative">
    
    <div class="box-title relative d-flex align-items-center">
        
        <h2>Filters for {{SerachOption}}</h2>
        <a (click)="onPopupClose()" class="close float-none ml-auto"><i class="material-icons">close</i></a>
    </div> 
    <div class="preset-filter-table">
        <ng-template #customTemplate1>           
        </ng-template>   
          <ngx-loading [show]="Loading" [config]="{ backdropBorderRadius: '3px',fullScreenBackdrop : true }" 
             [template]="customTemplate1"></ngx-loading> 
             <div class="search-input mt-2">       
                <input type="text" type="search" [(ngModel)]="PresetFilterSerach" class="form-control field-search " placeholder="Press ENTER to search" name="search2" (keyup.enter)="getAllFilter()" autocomplete="off" (input)="validateGroupQueryInput()"  (keypress)="validateSpace($event)">      
                <button type="submit" class="btn" (click)="getAllFilter()"></button>
                <button type="button" (click)="clearFilterSearchField()" *ngIf="PresetFilterSerach" class="btn-cross" aria-label="Clear" title="Clear search" >
                  <mat-icon>close</mat-icon>
                </button>
              </div> 
        <div class="table-responsive mt-3 table-data">
            <table class="table table-secondary">
                <thead>
                    <tr>
                        <th>Filter Name</th>
                        <th>Created By</th>
                        <th>Created On</th>
                        <!-- <th>Last Modified By</th>
                        <th>Last Modified On</th>                      -->
                        <th></th> 
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let data of FilterDetails;trackBy: trackByFn">                  
                        <td><span class="text-primary pointer" (click)="SelectFilter(data.FilterCollection,data.FilterName)">{{data.FilterName}}</span></td>
                        <td>{{data.CreatedBy}}</td>
                        <td>{{data.CreatedDate | date: 'MM/dd/yyyy'}}</td>
                        <!-- <td>{{data.ModifiedBy}}</td>
                        <td>{{data.ModifiedDate | date: 'MM/dd/yyyy'}}</td>                        -->
                        <td class="text-center">
                            <span class="closeicon pointer text-danger" matTooltip="Delete Filter"
                                (click)="clickMethod(data)"><svg xmlns="http://www.w3.org/2000/svg" width="18"
                                    height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1"
                                    stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash-2">
                                    <polyline points="3 6 5 6 21 6"></polyline>
                                    <path
                                        d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2">
                                    </path>
                                    <line x1="10" y1="11" x2="10" y2="17"></line>
                                    <line x1="14" y1="11" x2="14" y2="17"></line>
                                </svg>
                            </span>
                        </td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <td colspan="4" class="text-right" align="right">
                                    <mat-paginator [showFirstLastButtons]="true"  [length]="pager.total" [pageSize]="pager.limit"
                            (page)="pageEvent($event)" [pageSizeOptions]="pager.pageSizeOptions">
                            </mat-paginator> 
                        </td>
                    </tr>       
                </tfoot>
            </table>
            
        </div>
       
        <div *ngIf="SelectedCollectionObject!=''" class="mt-3">
           <h3 class="mb-3"><span class="font-400 mr-2">Filter Name </span>{{SelectedFilterName}}</h3>
            <div class="table-responsive filter-detail" >
                <table class="table table-secondary lh-base"> 
                    <tr><th width="110" class="font-500">Time Period </th><td>Today - {{convertJsonToArray(SelectedCollectionObject,'Days')}} Days</td></tr>
                    <tr *ngIf="SerachOption=='Recommendation'"><th class="font-500">Recommendations</th><td>{{convertJsonToArray(SelectedCollectionObject,'Recommendation')}}</td></tr>
                    <tr *ngIf="SerachOption!='Ageing'"><th class="font-500">Recruiters</th><td>{{convertJsonToArray(SelectedCollectionObject,'Recruiter')}}</td></tr>
                    <tr><th class="font-500">Country</th><td>{{convertJsonToArray(SelectedCollectionObject,'Country')}}</td></tr>
                    <tr *ngIf="SerachOption!='Verification' && SerachOption!='candidateConsidered' && SerachOption!='candidateInterview'"><th class="font-500">Skills</th><td>{{convertJsonToArray(SelectedCollectionObject,'skills')}}</td></tr>  
                    <tr *ngIf="SerachOption=='candidateConsidered' || SerachOption=='candidateInterview'"><th>Attribute</th><td>{{convertJsonToArray(SelectedCollectionObject,'Attribute')}}</td></tr>
                    <tr *ngIf="SerachOption=='candidateConsidered' || SerachOption=='candidateInterview'"><th>interviewType</th><td>{{convertJsonToArray(SelectedCollectionObject,'InterviewType')}}</td></tr>
                    <tr *ngIf="SerachOption=='candidateConsidered' || SerachOption=='candidateInterview'"><th>Recommendation</th><td>{{convertJsonToArray(SelectedCollectionObject,'RecommendationType')}}</td></tr>  
                    <tr *ngIf="SerachOption=='candidateConsidered' || SerachOption=='candidateInterview'"><th>Reviewer</th><td>{{convertJsonToArray(FilterObject,'reviewerType')}}</td></tr>
                </table>
            </div>
        </div>  
 
   
</div>

<div class="modal-button mt-3 text-center" *ngIf="SelectedCollectionObject!=''"> 
    <button class="btn btn-primary btn-md px-5" (click)="SubmitSelectedFilter()">Select</button></div>
</div> 