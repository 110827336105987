import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { NgForm, FormsModule } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Subscription } from 'rxjs';
import { JwtService } from 'src/app/services/core/jwt.service';
import { DashboardServiceService } from 'src/app/services/dashboard/dashboard-service.service';
import { LoggingMonitoringService } from 'src/app/services/logging.service';
import { SharedService } from 'src/app/services/shared.service';
import { SnackbarService } from 'src/app/services/snackBar/snackbar.service';
import { MatLegacyOptionModule } from '@angular/material/legacy-core';
import { MatLegacyAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyInputModule } from '@angular/material/legacy-input';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';
import { NgFor, NgIf } from '@angular/common';
import { MatLegacyRadioModule } from '@angular/material/legacy-radio';

@Component({
    selector: 'app-add-preset-filter',
    templateUrl: './add-preset-filter.component.html',
    styleUrls: ['./add-preset-filter.component.scss'],
    standalone: true,
    imports: [MatLegacyRadioModule, FormsModule, NgFor, NgIf, MatLegacyFormFieldModule, MatLegacyInputModule, MatLegacyAutocompleteModule, MatLegacyOptionModule]
})
export class AddPresetFilterComponent implements OnInit,OnDestroy {

  constructor(private jwtService: JwtService, @Inject(MAT_DIALOG_DATA) public dialogdata, private sharedService:SharedService, public dialogRef: MatDialogRef<AddPresetFilterComponent>, private service: DashboardServiceService,private snackBar: SnackbarService,private loggingService: LoggingMonitoringService) { }

  Model = {
    Filter_Name:'', 
    ID:""    
  }  
  private subscriptions: Subscription[] = [] 
  FilterListBySubscriber
  FilterListBySubscriber1
  FilterList
  FilterObject
  SerachOption
  isDisabled=false 
  ngOnInit(): void {
    this.selectedOption="Add"
    this.SerachOption=this.dialogdata.event;
    this.FilterList=this.dialogdata.Filter;
    this.FilterObject = JSON.parse(this.FilterList.FilterCollection); 
    this.getAllFilterList();
   
  }
  removeSpaces(e: { currentTarget: { selectionStart: any; }; which: number; preventDefault: () => void; }) {
    const startPos = e.currentTarget.selectionStart;
    if ((e.which === 32 && startPos === 0) || (e.which === 13 && startPos === 0)) {
      e.preventDefault();
    }
  }
  omit_special_char(event: { charCode: any; }) {
    const k = event.charCode;
    if (k != 58 && k != 44 && k != 45 && k != 46 && k != 47) {
      return ((k > 64 && k < 91) || (k > 96 && k < 123) || k === 8 || k === 32 || (k >= 48 && k <= 57));
    }
  }
  omit_validate_specialchar(event: { charCode: any; }) {
    const k = event.charCode;   
     if(k==33 || k==36 || k==37 ||k==44 || k== 58 || k== 60 || k== 61  || k== 62  || k== 64  || k== 63 
       || k== 91  || k== 92  || k== 93  || k== 94  || k== 96  || k== 123 || k== 124  ||  k ==125 ||  k == 126  
     || k==42 ||k==38 ||k==40 ||k==41 ||k==47 || k==59 || k==39|| k==34 || k==45 || k==95){
      
       return false}
  }
  cleartext() {
    this.Model.ID = "";
    (document.getElementById("Filter_Name") as HTMLInputElement).value = ""
  }
  getFilter(Id: string) {  
    if (Id != "" && Id != null && Id!=undefined) {
      if (this.FilterListBySubscriber1?.length > 0) {
        this.Model.ID = Id
        var f= this.FilterListBySubscriber1?.find(f => f.ID == Id).FilterName;   
        return f;    
      }
    }
  }
  validateSpace(e) {
    this.sharedService.validateBlankSpace(e);
  }
 
  getAllFilterList()
  {      
       const data={SubscriberId:this.jwtService.getSubscriberId(),SearchValue:this.SerachOption}
       this.subscriptions.push(
       this.service.getAllFilterList(data).subscribe(res=>{      
         this.FilterListBySubscriber=res;   
         this.FilterListBySubscriber1=res;
           },(err)=>{this.loggingService.logException(err);}))
  }
 
   searchFilterList(event) {
    this.FilterListBySubscriber = JSON.parse(JSON.stringify(this.FilterListBySubscriber1))
    if (event == "") {
      this.FilterListBySubscriber = JSON.parse(JSON.stringify(this.FilterListBySubscriber1))
    }
    else {
      if (event != undefined && this.FilterListBySubscriber1 != undefined) {
        this.FilterListBySubscriber = this.FilterListBySubscriber1?.filter(Group => Group.FilterName?.toString().toLowerCase().includes(event.toString().toLowerCase()))
      }
    }
  }
  convertJsonToArray(jsonObj,P)
  {

    let RetValue="";
    let ArrayObject =jsonObj; 
    if(P=='Days')
    {
       RetValue=ArrayObject['Days']
    }
    else if(P=='Recruiter')
    {    
        ArrayObject['Recruiter']?.forEach((value)=> {
          if(RetValue=="")
          {
            RetValue=value["name"];
          }
          else
          {
            RetValue=RetValue+","+value["name"];
          }       
    });     
    }
    else if(P=='Country')
    {    
        ArrayObject['Country']?.forEach((value)=> {
          if(RetValue=="")
          {
            RetValue=value["name"];
          }
          else
          {
            RetValue=RetValue+","+value["name"];
          }       
    });     
    }
    else if(P=='skills')
    {   
      
        ArrayObject['skills']?.forEach((value)=> {
          if(RetValue=="")
          {
            RetValue=value["name"];
          }
          else
          {
            RetValue=RetValue+","+value["name"];
          }       
    });     
    }
    else if(P=='Recommendation')
    {   
      
        ArrayObject['Recommendation']?.forEach((value)=> {
          if(RetValue=="")
          {
            RetValue=value["name"];
          }
          else
          {
            RetValue=RetValue+","+value["name"];
          }       
    });     
    }
    else if(P=='Attribute')
    {   
      
        ArrayObject['Attribute']?.forEach((value)=> {
          if(RetValue=="")
          {
            RetValue=value["name"];
          }
          else
          {
            RetValue=RetValue+","+value["name"];
          }       
    });     
    }
    else if(P=='InterviewType')
    {   
      
        ArrayObject['InterviewType']?.forEach((value)=> {
          if(RetValue=="")
          {
            RetValue=value["DisplayName"];
          }
          else
          {
            RetValue=RetValue+","+value["DisplayName"];
          }       
    });     
    }
    else if(P=='RecommendationType')
    {   
      
        ArrayObject['RecommendationType']?.forEach((value)=> {
          if(RetValue=="")
          {
            RetValue=value["Remark"];
          }
          else
          {
            RetValue=RetValue+","+value["Remark"];
          }       
    });     
    }
    else if(P=='reviewerType')
    {   
      
        ArrayObject['reviewerType']?.forEach((value)=> {
          if(RetValue=="")
          {
            RetValue=value["value"];
          }
          else
          {
            RetValue=RetValue+","+value["value"];
          }       
    });     
    }
    return RetValue;

  }
  onPopupClose() {   
    this.dialogRef.close('popClose')   
  } 
  selectedOption
  // RadioSelectionList: string[] = ['Add', 'Update'];
  RadioSelectionList = [
    { "name": "New","value": "Add", ID: "1","checked": true},
    { "name": "Update existing","value": "Update", ID: "2", "checked": false}
]
  onSubmit(form: NgForm)
  {
    if(form.valid)
    {
      this.isDisabled=true;
      if(this.selectedOption=="Update")
      {
        let itemSelected=this.FilterListBySubscriber1?.find(f => f.FilterName.toLowerCase().trim() === this.Model.Filter_Name.toLowerCase().trim());      
        if(itemSelected=="" || itemSelected==undefined ||itemSelected==null)
        {
          this.Model.ID = "";
          this.Model.Filter_Name="";          
          return form.invalid;
        }
        else{
          this.FilterList.ID=itemSelected.ID; 
          this.FilterList.FilterName=this.Model.Filter_Name;
        }             
      }
      else if(this.selectedOption=="Add")
      {
        this.FilterList.ID="0";
        this.FilterList.FilterName=this.Model.Filter_Name;
      } 
      else{
        this.snackBar.openSnackBar("Please select option from Add/Update"); 
        return 
      } 
      if(this.FilterList.FilterName!="" && this.FilterList.FilterName!=undefined && this.FilterList.FilterName!=null)
        {
      this.FilterList.selectedOption=this.selectedOption
      this.subscriptions.push(
      this.service.savePresetFilter(this.FilterList).subscribe(res=>{
        this.snackBar.openSnackBar(res["Message"]); 
        this.isDisabled=false;
        if(res["StatusCode"] != "409")
        {
          this. onPopupClose();
        }      
     },(err)=>{this.loggingService.logException(err);; this.isDisabled=false;}))
    }
    else{
      return form.invalid;
    }
    }
   }
   ngOnDestroy(): void {
    this.subscriptions.map(s => s.unsubscribe);
  }
}

