<div class="matModal">
    <div class="modal-header" fxLayout="row" fxLayoutAlign="space-between start">
        <h5 class="modal-title"> Change Password Form </h5>
        <button class="btn p-0 text-white" mat-dialog-close type="button" (click)="onDismiss()"><i class="material-icons">close</i></button>
    </div>

</div>



<div class="form">
  <div> 
    <form #submitForm="ngForm" (ngSubmit)="submitForm.valid && onSubmit(submitForm)">
        <!-- <form [formGroup]="resetForm" > -->
          
          <div class="form-group">
            <label for="OldPassword" class="form-label">Old Password <span class="req">*</span></label>
            <input type="password"  class="form-control" [ngClass]="{ 'is-invalid': submitForm.submitted && OldPassword.invalid }" minlength="6"
            name="OldPassword" #OldPassword="ngModel" [(ngModel)]="formModel.OldPassword" pattern="^(?=.*[A-Z])(?=.*[@#$%^&+=]).*$" required />
            
            <div *ngIf="submitForm.submitted && OldPassword.invalid" class="invalid-feedback">
                <div *ngIf="OldPassword.errors.required">Old Password is required</div>
            </div>
        </div>    
        <div class="form-group">
            <label for="NewPassword" class="form-label">New Password&nbsp;<small>(min 6)</small> <span class="req">*</span></label>
            <input type="password" class="form-control" [ngClass]="{ 'is-invalid': submitForm.submitted && NewPassword.invalid }" minlength="6" 
            name="NewPassword" #NewPassword="ngModel" [(ngModel)]="formModel.NewPassword" maxlength="25" pattern="^(?=.*[A-Z])(?=.*[@#$%^&+=]).*$" required />
            <div *ngIf="submitForm.submitted && NewPassword.invalid" class="invalid-feedback">
                <div *ngIf="NewPassword.errors.required">Password is required</div>
                <div *ngIf="NewPassword.errors.pattern">Password must be at least 6 characters and one lowercase,uppercase, integer and special character</div>
            </div>
        </div>
        <div class="form-group">
            <label for="ConfirmNewPassword" class="form-label">Confirm Password <span class="req">*</span></label>
            <input type="password" class="form-control" [ngClass]="{ 'is-invalid': submitForm.submitted && ConfirmNewPassword.invalid }" minlength="6" 
            name="ConfirmNewPassword" #ConfirmNewPassword="ngModel" pattern="{{ NewPassword.value }}" [(ngModel)]="formModel.ConfirmNewPassword" maxlength="25" pattern="^(?=.*[A-Z])(?=.*[@#$%^&+=]).*$" required />
            <div *ngIf="(submitForm.submitted || ConfirmNewPassword.touched) && ConfirmNewPassword.invalid" class="invalid-feedback">
                <div *ngIf="ConfirmNewPassword.errors.required">Confirm Password is required</div>
                <!-- <div *ngIf="ConfirmNewPassword.errors.confirmPassword.pristine">Password are not matching. Please re-enter password.</div> -->
                <!-- <div *ngIf="ConfirmNewPassword.errors.pattern">Password must be at least 6 characters and one lowercase,uppercase, integer and special character</div> -->
                <div *ngIf="ConfirmNewPassword.errors.pattern"> Password & Confirm Password does not match.</div>
            </div>
        </div>        
        <div class="error_txt" *ngIf="notMatch">
            <p>Password are not matching. Please re-enter password.</p>
        </div>

            <!-- <div class="col-sm-12">
                <div class="form-group">
                    <label class="form-label">Password </label>
                    <input class="input-readonly form-control" type="text" name="CandidateName" #CandidateName="ngModel"
                        [(ngModel)]="formModel.CandidateAssessment.CandidateName">
                    <div class="errorMsg" *ngIf="submitForm.submitted && CandidateName.invalid">
                        <span *ngIf="CandidateName.errors.required">{{Validation.candidate}}</span>
                    </div>
                </div>
            </div>           
            <div class="col-sm-12">
                <div class="form-group">
                    <label class="form-label">Confirm Password </label>
                    <input class="input-readonly form-control" type="tel" name="email" #email="ngModel"
                        [(ngModel)]="model.email" >
                    <div class="input-err" *ngIf="submitForm.submitted && email.invalid">
                        <span *ngIf="email.errors.required">{{Validation.email}}</span>
                    </div>
                </div>
            </div> -->
        <div class="form-bottom" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
            <button class="btn btn-primary" mat-flat-button type="submit">Submit</button>
        </div>

    </form>
  </div>
</div>