import { Injectable } from '@angular/core';

import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { SnackBarComponent } from './snack-bar.component';

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {
  constructor(private snackBar: MatSnackBar) { }

  openSnackBar(data, errorClass?) {
    setTimeout(() => {
      this.snackBar.openFromComponent(SnackBarComponent, {
        duration: 8000,
        horizontalPosition: 'right',
        verticalPosition: 'top',
        data: data,
        panelClass: ['successClass', errorClass]
      });
    }, 300);
  }
}
