import { Component, OnDestroy, OnInit } from '@angular/core';
import { LoggingMonitoringService } from './services/logging.service';
//import { AuthService } from './services/auth/auth.service';
//import { IpServiceService } from 'src/app/services/ip-service.service';
import { NgcCookieConsentService, NgcNoCookieLawEvent, NgcInitializeEvent, NgcStatusChangeEvent } from 'ngx-cookieconsent';
import { Subscription } from 'rxjs';
import { VideoConferenceService } from 'src/app/services/video-conference.service';
//import {publicIp, publicIpv4, publicIpv6} from 'public-ip';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { FooterComponent } from './components/common/footer/footer.component';
import { RouterOutlet } from '@angular/router';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { HeaderComponent } from './components/common/header/header.component';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: true,
    imports: [HeaderComponent, LoadingBarModule, RouterOutlet, FooterComponent]
})
export class AppComponent implements OnInit,OnDestroy {
  islogin:boolean=false;
  ipAddress:string='';
  private popupOpenSubscription: Subscription;
  private popupCloseSubscription: Subscription;
  private initializeSubscription: Subscription;
  private statusChangeSubscription: Subscription;
  private revokeChoiceSubscription: Subscription;
  private noCookieLawSubscription: Subscription;
  

  constructor(private loggingService: LoggingMonitoringService,private ccService: NgcCookieConsentService
    //,private ip:IpServiceService
    ,private videoConferenceService: VideoConferenceService,private client:HttpClient) {
     
  }

  // async getIP()  
  // {  
  //   //console.log(await publicIpv4());
  //   this.ip.getIPAddress().subscribe((res:any)=>{  
  //     //console.log('res ip service: ',res)
  //     //console.log('local ip address: ',res.ip)
  //     this.ipAddress=res.ip;  
  //     console.log(this.ipAddress);
  //   });  

  //   //this.ipAddress=this.client.get("https://api.ipify.org/?format=json");  
  // } 

  getUserIpAddress(){
    const ipServiceUrl=`${environment.ipServiceUrl}`;
    this.client.get(ipServiceUrl).subscribe(
      (value:any) => {
        //debugger;
        //console.log(value);
        this.ipAddress = value.ip;
        //alert(this.ipAddress);
       // console.log('User IP: '+this.ipAddress);
      },
      (error) => {
        //debugger;
        //console.log(error);
      }
    );
  }

  onActivate(event) {
    window.scroll(0, 0);
    // document.body.scrollTop = 0;
    // document.querySelector('body').scrollTo(0, 0)
  }

  ngOnInit(): void {
    {
      //console.log('init');
      //this.getIP();
      //this.getUserIpAddress();
      //const userId = this.jwtservice.getCurrentUserId();
      //const SubscriberId = this.jwtservice.getSubscriberId();
     
      //this.saveCookiesConsentDetails(cookiesConsent);
      // subscribe to cookieconsent observables to react to main events
      this.popupOpenSubscription = this.ccService.popupOpen$.subscribe(() => {
        //console.log('open');
        // you can use this.ccService.getConfig() to do stuff...
      });

      this.popupCloseSubscription = this.ccService.popupClose$.subscribe(() => {
        // you can use this.ccService.getConfig() to do stuff...
        //console.log('Consent popup closed')
      });

      this.initializeSubscription = this.ccService.initialize$.subscribe(
        (event: NgcInitializeEvent) => {
          //console.log('Subscription initialized ',event.status)
          // you can use this.ccService.getConfig() to do stuff...
        }
      );

      this.statusChangeSubscription = this.ccService.statusChange$.subscribe(
        (event: NgcStatusChangeEvent) => {
          // you can use this.ccService.getConfig() to do stuff...
          //console.log('status changed',event)
          const cookiesConsent = {
            IPAddress: this.ipAddress,
            CookiesId: '',
           
            Userid:'',
            SubscriberId:0
          }
          //console.log(cookiesConsent);
          this.saveCookiesConsentDetails(cookiesConsent);
        }
      );

      this.revokeChoiceSubscription = this.ccService.revokeChoice$.subscribe(
        () => {
          //console.log('revoke');
          const cookiesConsent = {
            IPAddress: this.ipAddress,
            CookiesId: '',
           
            Userid:'',
            SubscriberId:0
          }
          //console.log(cookiesConsent);
          this.saveRevokeCookiesConsentDetails(cookiesConsent);
         
        }
      );

      this.noCookieLawSubscription = this.ccService.noCookieLaw$.subscribe(
        (event: NgcNoCookieLawEvent) => {
          // you can use this.ccService.getConfig() to do stuff...
          //console.log('Subscription initialized ',event.country)
        }
      );
    }
    if (localStorage.getItem('islogin') === null) {
      localStorage.setItem('islogin', 'false')    
    }
  }

  saveCookiesConsentDetails(params:any): boolean {
    try {
     
      this.videoConferenceService.addCookiesConsentDataWithHistory(params).subscribe(response => {
        //console.log('save cookies consent data: ',response,response.data);
        if (response.success ===true) {
          return true;
        }
        else {
          return false;
        }
      }, (err) => {
        //this.commonService.checkTokenValidity(err);
        return false;
      })
    } catch {
      //this.commonService.commonSnakeBar();
      return false;
    }
  }

  saveRevokeCookiesConsentDetails(params:any): boolean {
    try {
     
      this.videoConferenceService.AddRevokeCookiesConsentDataWithHistory(params).subscribe(response => {
        //console.log('save revoke cookies consent data: ',response,response.data);
        if (response.success ===true) {
          return true;
        }
        else {
          return false;
        }
      }, (err) => {
        //this.commonService.checkTokenValidity(err);
        return false;
      })
    } catch {
      //this.commonService.commonSnakeBar();
      return false;
    }
  }
 
  ngOnDestroy() {
    // unsubscribe to cookieconsent observables to prevent memory leaks
    this.popupOpenSubscription.unsubscribe();
    this.popupCloseSubscription.unsubscribe();
    this.initializeSubscription.unsubscribe();
    this.statusChangeSubscription.unsubscribe();
    this.revokeChoiceSubscription.unsubscribe();
    this.noCookieLawSubscription.unsubscribe();
  }

}
