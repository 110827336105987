import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../core/api.service';
import { environment } from 'src/environments/environment';
import { apiPaths } from 'src/app/setting/apiPath';
import { JwtService } from '../core/jwt.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private apiService: ApiService, private jwtService: JwtService) { }

  loginUser(data): Observable<any> {


    return this.apiService.POSTVDIAL(`${apiPaths.GET_TOKEN}`, data);
  }
  RefreshToken(data): Observable<any> {
    //Manu Datta: 20210512, RefreshToken
    return this.apiService.GETVDIAL(`${apiPaths.GET_REFRESH_TOKEN}`);
  }

  resetPassword(data): Observable<any> {

    return this.apiService.POSTVDIAL(`${apiPaths.RESET_PASSWORD}`, data);
  }
  //Manu Datta: 20200908, API for get recruiter list from veridial
  getRecriterList(data): Observable<any> {

    return this.apiService.POSTVDIAL(`${apiPaths.GET_RECRUITER_LIST_VDIAL}`, data);
  }

  //Suman kumar: 2020-09-17, Function to add the user view data for recruiter verification count
  AddUpdateUserViews(data): Observable<any> {

    return this.apiService.POST(`${apiPaths.Save_RECRUITER_USER_VIEW_DATA}`, data);
  }

  getLookupData(): Observable<any> {

    return this.apiService.GET(`${apiPaths.GETLOOKUPDATA}`);
  }

  updateUserConfig(data): Observable<any> {
    return this.apiService.POSTVDIAL(`${apiPaths.UPDATEUSERCONFIG}`, data);
  }

  // getCalendarLocale() {
  //   //     if (locale != null && locale != '' && locale != undefined) {

  //   // }
  //   let dateformat = this.jwtService.getUserDateFormat();
  //   alert(dateformat);

  //   let userLocale: string = 'en-US';
  //   if (dateformat === 'MM/dd/yyyy') {
  //     userLocale = 'en-US';
  //   }
  //   else if (dateformat === 'dd/MM/yyyy') {
  //     userLocale = 'en-GB';
  //   }

  //   alert(userLocale);

  //   return userLocale;
  // }

  // getCalendarLocale(): Observable<string> {
  //   let dateformat = this.jwtService.getUserDateFormat();


  //   alert(dateformat);
  //   if (dateformat === 'MM/dd/yyyy') {
  //     this.calendarLocale.next('en-US');
  //   }
  //   else if (dateformat === 'dd/MM/yyyy') {
  //     this.calendarLocale.next('en-GB');
  //   }
  //   alert('GET function called');
  //   return this.calendarLocale.asObservable();
  // }

  // updateCalendarLocale(locale: string) {
  //   if (locale === 'MM/dd/yyyy') {
  //     this.calendarLocale.next('en-US');
  //   }
  //   else if (locale === 'dd/MM/yyyy') {
  //     this.calendarLocale.next('en-GB');
  //   }
  //   else {
  //     this.calendarLocale.next('en-US');
  //   }
  //   alert('SEt CALLED');
  // }
}