export class apiPaths {
  public static GET_CANDIDATE_PROFILE = "Recruiter/GetUserProfile";
  public static GET_USER_PROFILE = 'v1/Account/GetUserDetails';
  public static vkGetMenuDetails = 'v1/Account/GetVKMenuPermissionDetails';
  public static CANDIDATE_ASSESSMENT_CARDVIEW = "CandidateAssessement/GetAllCandidateAssessmentCardView";
  public static CANDIDATE_ASSESSMENT = "CandidateAssessement/GetAllCandidateAssessment";
  public static CANDIDATE_ASSESSMENT_DUPLICATE = "CandidateAssessement/GetAllCandidateAssessmentDuplicate";
  public static CANDIDATE_ASSESSMENT_EDIT = "CandidateAssessement/EditAssessmentFormForCandidate";
  public static GET_CANDIDATE_ASSESSMENT_DETAIL = "CandidateAssessement/GetCandidateAssessmentDetailById";
  public static Get_Candidate_ScoreCard_Details = "CandidateAssessement/GetCandidateScoreCardDetails";
  public static GET_PROFILES_LIST = "Recruiter/GetSavedProfilesListByUserId";
  public static GET_PROFILE_DETAILS = "Recruiter/GetSavedProfileDetailsBySavedprofileId";
  public static GET_RECRUITER_LIST = "Recruiter/GetRecruiterBySubscriberId";
  public static CANDIDATE_PERSONAL_DETAIL = "candidate";
  public static CREATE_ASSESSMENT = 'CandidateAssessement/CreateAssessmentFormForCandidates';
  public static GET_REFERENCE_VERIFY = 'Verify/GetReferenceVerificationListById';
  public static ADD_CANDIDATE_REF = 'Verify/AddCandidateReferences'
  public static GET_REFRENCE_VERIFY_REPORT = 'Verify/GetReferenceVerificationReportByReferenceId';
  public static GET_TIME_ZONE = 'Common/GetMasterTimeZoneList';
  public static IMAGE_UPLOAD = 'common/ImageUpload';
  public static UpdateUserProfile: 'Account/UpdateUserProfile';
  public static ENABLE_RECORD_NEWVOICESAMPLE_STATUS = 'Candidate/RecordNewSampleEnabled';
  // Schedule VIDEO SCREEN API
  public static CHECK_INTERVIEWUSER_EXISTWITHEMAIL = 'ScheduleVideo/GetInterviewerUserByEmail';
  public static CHECK_INTERVIEWUSER_EXISTWITHPHONE = 'ScheduleVideo/GetInterviewerUserByPhone';
  public static GET_SAVE_VIDEO_SCREEN = 'ScheduleVideo/GetSaveInterviewDetails';
  public static GET_SAVE_ROOM_DETAIL = 'ScheduleVideo/GetSaveRoomDetails';
  public static GET_ROOMSID_DETAIL = 'ScheduleVideo/GetSaveRoomDetailsParticipant';
  public static GET_COMPOSITION_REFRESH = 'ScheduleVideo/GETCOMPOSITIONREFRESH';
  public static GET_COMPOSITION_MANUAL = 'ScheduleVideo/CreateCompositionData';
  public static VIDEO_SCREEN_VERIFY_CHECK = 'ScheduleVideo/CheckSubcribersVideoCallsCount';
  public static VIDEO_SCREEN_SAVE = 'ScheduleVideo/SaveInterviewDetails';
  public static INTERVIEW_LEVEL_CLEAR_STATUS = 'Common/GetSelectedCandidateDetails';
  public static RESEND_INTERVIEWDETAILS = 'ScheduleVideo/resendInterviewDetailsToInterviewrs';
  //for without authentication
  public static RESEND_INTERVIEWDETAILS_PARTICIPENT = 'ScheduleVideo/resendInterviewDetailsToParticipent';
  
  public static INTERVIEWER_SAVE = 'ScheduleVideo/SaveInterviewerDetails';
  public static INTERVIEWER_UPDATE = 'ScheduleVideo/UpdateInterviewerDetails';
  public static SELECTED_CANDIDATE_BY_RECOMMANDATION = 'Common/GetSelectedCandidateByRecommandation';
  public static GET_ATTRIBUTE_CANDIDATE_BY_RECOMMANDATION = 'Common/GetAllRecommandationbyRecuriterDetails';
  public static GET_ATTRIBUTE_CANDIDATE_BY_RECOMMANDATION_MAILER = 'Common/GetAllRecommandationMailerDetails';
  public static UPDATE_INTERVIEWER_STATUS = 'ScheduleVideo/UpdateInterviewerStatus';
  public static INTERVIEWER_LIST = 'ScheduleVideo/getInterviewerList';
  public static DASHBOARD_SELECTED_CANDIDATE = 'Common/GetSelectedCandidateByCountry';
  public static DASHBOARD_SELECTED_CANDIDATE_BY_RECOMMANDATION = 'Common/GetSelectedCandidateByRecommandationMonthly';
  public static VIDEO_SCREEN_INTERVIEWUsers_UPDATE = 'ScheduleVideo/UpdateInterviewUsersDetail';
  public static VIDEO_SCREEN_INTERVIEWUsersScreenShareStatus_UPDATE = 'ScheduleVideo/UpdateInterviewersScreenShareStatus';
  public static GET_INTERVIWER_LIST_DETAILS = 'Common/GetInterviwerDetailsList';
  public static VIDEO_SCREEN_CANCELLATION = 'ScheduleVideo/InterviewCancellationDetails';
  public static SCHEDULED_VIDEO_DETAIL = 'ScheduleVideo/ScheduleVideoCall';
  public static GET_CANDIDATE_ASSESSEMENT_BY_VIDEO_ACCESS_CODE = 'ScheduleVideo/AssessmentId';
  public static GET_CANDIDATE_ASSESSEMENT_DETAILS_BY_VIDEO_ACCESS_CODE = 'ScheduleVideo/GetCandidateAssessementDetailsByIdVideoAccessCode';
  public static CREATE_CANDIDATE_ASSESSMENT_DETAILS = 'ScheduleVideo/CreateCandidateAssessementDetails';
  public static ALL_INTERVIEWS = 'ScheduleVideo/GetInterviewByRecruiter';
  public static InterviewDetails = 'ScheduleVideo/GetInterviewByInterViewId';
  public static InterviewDetailsInfo = 'ScheduleVideo/GetInterviewScheduleInfo';

  public static GET_RECORDINGS = 'ScheduleVideo/Getrecordings';
  public static GET_COMPOSITION_VIDEO_URL = 'ScheduleVideo/GetCompositionVideoUrl';
  public static UPDATE_INTERVIEW_RECORDING_STATUS = 'ScheduleVideo/UpdateInterviewRecordingStatus';
  public static UPDATE_CANDIDATE_RECORDING_STATUS = 'ScheduleVideo/UpdateCandidateRecordingStatus';
  public static GET_RECOMMENDATION_LIST = 'Common/GetRecommendation';
  public static VALIDATE_URL_TOKENS = 'Common/ValidateUrlToken';
  // TECH SCREEN API
  public static TECH_SCREEN_ASSEMEMT_SAVE = 'Assessment';
  public static CANCEL_TECH_SCREEN_ASSEMEMT = 'Assessment/cancelcandidateassessmenttest';
  public static SCHEDULE_SCREEN_TEST = 'Assessment/ScheduleScreeningTest';
  public static VIEW_SCHEDULE_SCREEN_TEST = 'Assessment/ViewScheduleScreeningTest'
  // VERIFICAITON SCREEN API
  public static VERIFY_CANDIDATE_AGE = 'Verify/VerifyCandidateAgeVerificationById'

  //Preset filter
  public static savePresetFilter = 'PresetFilter/SaveFilter';
  public static GetFilterByName = 'PresetFilter/GetFilterByName';
  public static getAllFilter = 'PresetFilter/GetAllFilters';
  public static GetAllFiltersBySubscriberId = 'PresetFilter/GetAllFiltersBySubscriberId';
  public static DeleteFilter = "PresetFilter/DeleteFilter";
  //Interviewer Report
  public static GET_INTERVIWER_SKILL_LIST = 'Common/GetInterviwerSkillsList';
  public static GET_INTERVIWER_GROUP_NAME_LIST = 'Common/GetInterviwerGroupnameList';
  public static GET_INTERVIWER_DETAILS = 'Common/GetInterviwerInterviewDetails';
  public static DASHBOARD_INTERVIWER_INTERVIEW_DETAILS = 'Common/GetInterviwerInterviewGraphDetails';
  public static DASHBOARD_INTERVIWER_INTERVIEW_STACKED_BAR = 'Common/GetInterviwerInterviewStackedHorizontalBar';

  //Interviewer Details
  public static GET_INTERVIEWER_LIST = "Recruiter/GetInterviewerDetails";
  public static GET_SAVE_INTERVIEWER_BY_ID = 'Recruiter/GetInterviewerDetailsById';
  public static UPDATE_INTERVIEWER_BY_ID = 'Recruiter/UpdateInterviewerDetails';

  // IS SCAN API
  public static FILE_UPLOAD = 'upload';
  public static PARSE_RESUME_UPLOAD = 'staffingagency/parseresume';
  public static GET_VERIFY_ID = 'Verify/GetCandidateLicenseVerificationReportById';
  public static VERIFY_ID_SCAN = "Verify/UpdateAndVerifyById"

  // CANDIDATE PROFILE CREATE / UPDATE
  public static UPLOAD_RESUME = 'staffingagency/uploadnewfile'
  public static CREATE_CANDIDATE_PROFILE = 'staffingagency/uploadresume'
  public static UPDATE_CANDIDATE_PROFILE = 'staffingagency/updatecandidateprofile'
  public static GET_COUNTRIES = 'Common/GetCountryList'
  public static GET_STATES = 'Common/GetStateListById';
  public static GET_CLIENT = 'Common/GetClient';
  public static GET_JOBTITLE = 'Common/GetJobTitle';

  public static GET_CITES = 'Common/GetCityListById';
  public static CANDIDATE_PROFILE = 'staffingagency/candidatedetails'
  public static GET_NATIONALITY = 'staffingagency/searchVeriklickNationality'
  public static GET_NATIONALITYLIST = 'Common/GetNationalityList';
  public static SEARCH_NATIONALITYLIST = 'Common/SearchNationalityList';
  public static GET_COUNTRY_CODE = 'Common/GetCountryCode';
  public static CREATECANDIDATEPROFILEWithVerisource = 'JobSeeker'
  public static CREATECANDIDATEPROFILEBYEXCELSHEET = 'SaveJobSeekerlist';
  public static GetMaxDurationOfVideoInterview = 'ScheduleVideo/GetMaxDurationOfVideoInterview';
  public static GetCountOfAudioCallverification = 'ScheduleVideo/GetCountOfAudioCallverification';
  public static GetCountOfFaceVerificationInInterview = 'ScheduleVideo/GetCountOfFaceVerificationInInterview';
  public static DELETE_PROFILE_IMAGE = 'JobSeeker/DeleteUploadImage'
  public static DELETE_RESUME = 'JobSeeker/DeleteResume'
  public static DELETE_PROFILE_UPLOADED_IMAGE = 'JobSeeker/DeleteUploadedImage'  // without auth delete uploaded image
  public static SAVECANDIDATEPROFILEWithVerisource = 'JobSeekerProfile'  // Without auth save candidate data
  public static UPDATE_CANDIDATE_PROFILE_WithVerisource = 'JobSeekerUpdateProfile'  // Without auth Update candidate data

  public static UPDATECANDIDATEPROFILEWithVerisource = 'JobSeekerUpdate'

  //  Get  Country/State/City List with Auth
  public static GET_COUNTRIES_List = 'Common/GetCountry'
  public static GET_STATES_List = 'Common/GetStateById';
  public static GET_CITES_List = 'Common/GetCityById';

  //Twilio Audio call
  public static GetTwilioAudioCallToken = "Twilio/GetTokenForAudioCall";
  public static SaveAudioCallDetails = "audio/SaveAudioCallDetails";
  public static UpdateAudioCallDetails = "audio/UpdateAudioCallDetails";
  public static SubscriberAudioCallsCount = 'Verify/CheckSubcribersCallsCount';
  public static GetAudioCallListForVerification = 'Verify/GetAudioCallListForVerification';
  public static RejectAudioCall = 'Audio/RejectAudioCall';

  //countries
  public static getGuestUserCountries = 'guestuser/countries';
  public static getCountries = 'staffingagency/countries';
  public static getverisourceCandidateData = 'staffingagency/candidatedetails';

  //VERIFY VOICE
  public static GET_AUDIO_CALL_LIST = 'Verify/GetAudioCallListForVerification'
  public static GET_VOICE_VERIFY_REPORT = 'Verify/GetVoiceVerificationReport'
  public static VERIFY_AUDIO_CALL = 'Verify/VerifyCandidateVoiceVerification'
  public static ENROLL_VOICE_FILE = 'Verify/EnrollVoiceFile'
  public static GET_ENROLLED_VOICE_FILE = 'Verify/GetEnrolledCallSid'
  public static GET_ENROLLED_HISTORY = 'Verify/GetEnrolledHistoryId'
  public static GET_ENROLLED_VERIFIED_CALL_LIST = 'Verify/GetEnrolledCallVerificationList'
  // Send profile link
  public static Send_Profile_Link = 'CandidateProfileEmailLink'
  public static Candidate_Profile = 'Candidate'

  //VERIFY EMAIL EXIXT
  public static Check_Email = 'Candidate/GetCandidateIdByEmailorPhone'
  public static Check_EmailId = 'JobSeeker/GetSearchCandidateEmailOrPhone'
  public static Check_phone = 'JobSeeker/GetSearchCandidatePhone'  // with no auth
  public static Check_Candidate_EmailId = 'JobSeeker/GetSearchCandidateEmailId'
  //Token
  public static GET_TOKEN = 'v1/Authentication/Token';
  public static GET_REFRESH_TOKEN = 'v1/Authentication/RefreshToken';

  //
  public static Get_Assessment_Test_Questions = 'Assessment/GetAssessmentTestQuestions'
  public static Save_Candidate_Assessment_TestResponse = 'Assessment/SaveCandidateAssessmentTestResponse'
  public static Get_Assessment_test_CandidateDetails = 'Assessment/test'

  // Assesment Report
  public static GET_PROBLEM_TYPE = 'Assessment/GetProblemType'
  public static SAVE_REPORT_PROBLEM = 'Assessment/SaveReportProblem'
  public static SAVE_RATING = 'Assessment/SaveRating'

  public static Check_And_Compile_Code = 'Assessment/CheckAndCompileCode'
  public static Get_Submissions_Details = 'Assessment/GetSubmissionsDetails'
  public static Get_Problem_Output = 'Assessment/GetProblemOutput'
  public static Get_Assesment_Report = 'Assessment/GetReportData'
  public static Get_Assesment_Ratings = 'Assessment/GetRating'
  public static Get_Assesment_Report_Link = 'Assessment/GetAssessmentReportLink'
  public static Get_Candidate_Verify = 'Verify/StatusUpdate/'

  // Dashboard
  public static FILE_CSV_UPLOAD = 'upload';
  public static FILE_EXL_UPLOAD = 'Bulkupload';

  // VIDEO SCREEN API
  public static GET_VIDEO_TOKEN = 'ScheduleVideo/GetTokenDetails';
  public static VIDEO_STATUS_CALLBACK = 'ScheduleVideo/SaveVideoCallStatusCallBackDetail';

    // VIDEO conference SCREEN API
    public static GET_VIDEOROOM_CONN_PARTICIPANT = 'ScheduleVideo/GetVRoomConnectedParticipant';
    public static GET_VIDEO_CONF_TOKEN = 'ScheduleVideo/GetVideoTokenDetails';
    public static GET_VIDEO_ACCESS_DETAILS = 'ScheduleVideo/GetVideoAccessCodeDetails';
    public static GET_VIDEO_ACCESS_DETAIL = 'ScheduleVideo/Updatestatusofvideoscheduled';
    public static SaveRoomStatus = 'ScheduleVideo/SaveRoomStatus';
    public static SaveRoomParticipantStatus = 'ScheduleVideo/SaveParticipantStatus';
    public static UpdateInterviewAllowMuteStatus = 'ScheduleVideo/UpdateInterviewAllowMuteStatus';
    public static Get_Interviewer_User_Details = 'ScheduleVideo/GetInterviewUserDetails';
    public static GetOwnScreenShareStatus = 'ScheduleVideo/GetOwnScreenShareStatus';
    public static GetInterviewTotalInterviewerCount = 'ScheduleVideo/GetInterviewTotalInterviewerCount';
    public static GetInterviewMeetingPassword = 'ScheduleVideo/GetInterviewMeetingPassword';
    public static SurvaleVirtusaAPI = 'ScheduleVideo/SurvaleVirtusaAPI';
    public static GET_VOICE_CALL_LIST = 'ScheduleVideo/GetCallList';


  public static Check_EmailIdOrPhone = 'ScheduleVideo/GetSearchCandidateEmailOrPhone'
  public static UpdateStatus_OfVideoScheduled = 'ScheduleVideo/GetVideoAccessCodeDetails';
  public static UpdateEmailAcceptance_status_VideoSchedule = 'ScheduleVideo/UpdateEmailAcceptanceStatus';
  public static updateMsTeamsAttendStatus = 'ScheduleVideo/updateMsTeamsAttendStatus';

  public static GetSubcriberIdHere = 'ScheduleVideo/GetSubcriberIdHere';

  public static MailInterviewersToJoin = 'ScheduleVideo/MailInterviewersToJoin';
  public static SaveCoderPadQuestion = 'ScheduleVideo/SaveCoderPadInterviewerQuestion';
  public static GetCoderPadQuestions = 'ScheduleVideo/GetCoderPadQuestionsByInterviewId';
  public static GetCoderPadQuestionByQuestionId = 'ScheduleVideo/GetCoderPadQuestionsByQuestionId';
  public static GetExistInterviewerData = 'ScheduleVideo/GetExistAsInterviewer';
  

  // VIDEO conference chat API
  public static GET_Teams_Application_TOKEN = 'ScheduleVideo/GETTeamsAppTOKEN';
  public static GET_Teams_RecordingLink = 'ScheduleVideo/GETTeamsRecordingUrl';
  public static GET_Teams_Report = 'ScheduleVideo/GETTeamsMeetingreport';

  public static GET_VIDEO_CHAT_TOKEN = 'ScheduleVideo/GetChatTokenDetails';

  // Dice
  public static DICE_LOGIN = 'DiceInfo'
  public static DICE_SEARCH = 'SearchInfo'

  public static Employment_Type = 'Common/GetEmploymentTypeList'
  public static Work_Authorization = 'Common/GetWorkAuthorizationList'
  public static Compensation = 'Common/GetCompensationList'
  public static Travel_Preferences = 'Common/GetTravelPreferencesList'
  public static Years_Experience = 'Common/GetYearsExperienceList'
  public static Degree = 'Common/GetDegreeList'
  public static Security_Clearance = 'Common/GetSecurityClearanceList'
  public static Check_Subscriber_View_Count = 'CheckSubcribersViewCount'
  public static View_Profile = 'ViewProfile'

  // Get video screen graph data
  public static GET_VideoScreenData = "Recruiter/GetVideosScreenInterview";
  public static GET_VideoScreenCompleteData = "Recruiter/GetVideosScreenCompleteInterview";
  public static GET_VideoScreenPendingData = "Recruiter/GetVideosScreenPendingInterview";

  //Candidate skill location marking for google map api
  public static GET_SkillLocationMarkingData = "Candidate/GetCandidatesSkillMapMarking";

  public static RESET_PASSWORD = 'v1/Account/ChangePassword';
  //Manu Datta: 20200908, API for get recruiter list from veridial
  public static GET_RECRUITER_LIST_VDIAL = 'v1/Recruiter/GetRecruiterLisitng';

  //Suman: 2020-09-17, path to add the user view data for recruiter verification count
  public static Save_RECRUITER_USER_VIEW_DATA = 'Subscriber/AddUpdateUserViews';

  public static GET_PROFILE_IMAGE = 'v1/Account/GetVKUserProfileImage';   //Get image to s3 with auth
  public static GET_PROFILE_S3DOCUMENT_URL = 'v1/Account/GetVKUserProfileResume';    //Get resume to s3 with auth
  public static UPLOADTOS3_PROFILE_IMAGE = 'v1/Account/UpdateImage';         //upload image to s3 with auth
  public static FaceBiometrics_IMAGE_Save = 'FaceBiometrics/SaveFaceBiometrics';
  public static FaceBiometrics_IMAGE_Verify = 'FaceBiometrics/SaveFaceBiometricsDetails';
  public static FaceBiometrics_IMAGE_CheckCandidateEnroll = 'FaceBiometrics/FaceBiometrics_IMAGE_CheckCandidateEnroll';
  public static FaceBiometrics_InterviewListBasedonCanId = 'FaceBiometrics/GetFBProfileInterviewlistBasedonCandidateid';
  public static FaceBiometrics_dropdownInterviewList = 'FaceBiometrics/GetFaceBiometrics_dropdownInterviewList';
  public static FaceBiometrics_CandidateVideoUrl = 'FaceBiometrics/CreateFaceBioCandidateVideoUrl';

  public static UPLOADTOS3_PROFILE_RESUME = 'v1/Account/UpdateResume';       //upload resume to s3 with auth

  public static UPLOADTOS3_PROFILE_RESUME_NOAUTH = 'v1/Account/UpdateResumeWithoutAuth';  //upload resume with no auth
  public static GET_PROFILE_S3DOCUMENT_URL_NOTAUTH = 'v1/Account/GetVKUserProfileResumeWithoutAuth';  //Get resume to s3 with no auth
  public static UPLOADTOS3_PROFILE_IMAGE_NOAUTH = 'v1/Account/UpdateImageWithoutAuth';  //upload image with no auth
  public static GET_PROFILE_S3IMAGE_URL_NOTAUTH = 'v1/Account/GetVKUserProfileImageWithoutAuth';  //Get image to s3 with no auth



  //Tech screen question upload
  public static UPLOADFILE = 'uploadQuestionCsvFile';
  // remove Image Background
  public static GET_PHOTO_FROM_DOCUMENT = 'Reader';
  public static GET_PHOTO = 'Reader/Convert'

  //Dashboard Api
  public static GET_RECURITER = 'Common/RecuriterList';
  public static GET_SKILLS = 'Common/SkillList';
  public static GET_PRIMARY_SKILL_LIST = 'Report/GetPrimarySkillList';
  public static GET_ATTRIBUTES = 'Report/AttributeList';
  public static GET_RECRUITER_LIST_DETAILS = 'Report/GetRecuriterDetailsList';
  public static GET_INTERVIEW_DETAILS = 'Common/GetAllInterviewDetails';
  public static ALL_DASHBOARD = 'Common/GetInterviewDetailbyDateWise'; 
  public static ALL_DASHBOARD_BY_RECURITER_DETAILS_MAILER = 'Common/GetAllInterviewDetailsbyRecuriterDetails';
  public static ALL_DASHBOARD_INTERVIEW_DETAILS = 'Common/GetInterviewDetails';  
  public static DASHBOARD_BAR_GRAPH_DETAILS = 'Common/GetInterviewGraphDataDetails';
  public static DASHBOARD_RECURITER_DETAILS = 'Common/GetAllInterviewDetailsbyRecuriter';
  public static DASHBOARD_BUSINESS_DETAILS = 'Common/GetAllInterviewDetailsbyPrimarySkills';

  //Interview Details
  public static INTERVIEW_DETAILS_BY_SUBSCRIBERID = 'Report/GetInterviewDetailsBySubscriberID';
  public static DASHBOARD_BAR_GRAPH = 'Report/GetInterviewGraphDataDetailsbySubscriberId';
  public static ALL_DASHBOARD_BY_DATE = 'Report/GetInterviewDetailbyDate';
  public static GET_INTERVIEW_DETAILS_GROUPBY = 'Report/GetAllInterviewDetailsbySubscriberId';
  public static ALL_DASHBOARD_BY_RECURITER_DETAILS = 'Report/GetAllInterviewbyRecuriterDetails';
  public static ALL_DASHBOARD_BY_PANEL_DETAILS = 'Report/GetAllInterviewbyPanelDetails';
  // Schedule VIDEO SCREEN API

  public static RoomSpeakerMuteUnmute = 'ScheduleVideo/RoomSpeakerMuteUnmute';

  //flocareer    
  public static GET_Flocarerr_RecordingLink = 'FloCareerIntegration/GETFlocareerRecordingUrl';
  public static GetExistInterviewerDataFlocareer = 'FloCareerIntegration/GetExistInterviewerDataFlocareer';


  //fullfillment calculator
  public static GET_TPJobPullDetail = 'Common/GetThirdpartyJobPullDetails';
  public static GET_JOBFieldDetailsMaster = 'Common/GetJobDetailsMaster';
  public static GET_JOBFieldDetails = 'Common/GetJobFieldsDetails';
  public static GET_AllJobIDDetails = 'Common/GetAllJobID_JobFieldsDetails';
  public static GET_AllJobIDDetailsByGroupId = 'Common/GET_AllJobIDDetailsByGroupId';
  public static GET_GroupDetails_days_to_hire = 'Common/GetJobDetailsDaysTohire';
  public static GET_JobGroupName = 'Common/GetJobGroupName';
  public static SaveJobGroupDetails = 'Common/SaveJOBGroupDetails';
  public static SaveJobGroup = 'Common/SaveJobGroup';
  public static DeleteJobGroup = 'Common/DeleteJobGroup';
  public static SelectJobGroup = 'Common/SelectJobGroup';
  public static DeleteJob = 'Common/DeleteJob';
  public static SaveJobIdDaysTohireFieldsDetails = 'Common/SaveJobIdDaysTohireFieldsDetails'
  public static GetCandidateScreenShareStatus = 'ScheduleVideo/GetCandidateScreenShareStatus';
  public static GetCandidateCodePadAttemptCountByQuestionId = 'ScheduleVideo/GetCandidateCodePadAttemptCountByQuestionId';
  public static ParseResume = 'Common/ParseResume';

  //Feedback
  public static ResendAssesmentOTP = 'FeedBackForm/ResendAssesmentOTP';
  public static InterviewerOTPStatusUpdate = 'FeedBackForm/InterviewerOTPStatusUpdate';
  public static INTERVIEWER_DATA = 'FeedBackForm/GetInterviewersByCode';
  public static PendingFeedbackData = 'FeedBackForm/PendingFeedbackData';
  public static SendFeedbackReminder = 'FeedBackForm/SendFeedbackReminder';
  public static Get_Interviewer_assesment_verify_Details = 'FeedBackForm/GetInterviewerVerifyDetails';
  public static GetInterviewersDetails = 'FeedBackForm/GetInterviewerDetailsByMail';
  public static feedbackTemplates = 'FeedBackForm/GetFeedBackTemplate';
  public static SaveFeedbackTemplateDetails = 'FeedBackForm/SaveFeedbackTemplateDetails';
  public static DeleteFeedbackTemplateDetails = 'FeedBackForm/DeleteFeedbackTemplateDetails';


  //Analytics Mail Schedule
  public static GET_ALL_SCHEDULE_MAIL_DETAILS = 'Report/GetAllScheduleMail';
  public static GET_DAYS = 'Report/DaysList';
  public static INSERT_ANALYTICS_MAILING_DETAILS = 'Report/SaveAnalyticsMailDetails';
  public static GET_BY_ID_ANALYTICS_MAILING_DETAILS = 'Report/GetAnalyticsMailDetails';
  public static DELETE_ANALYTICS_MAILING_DETAILS = 'Report/DeleteAnalyticsMailDetails';
  public static GET_FILTER_OPTIONS = 'Report/GetFilterOptions';

  //optout GDPR
  public static SaveJobseekerOptoutdataToVeridial = 'V1/GDPR/AddEditOptoutDataForVeriklickJobseeker';

  public static AddCookiesConsentDataWithHistory = 'V1/GDPR/AddCookiesConsentDataWithHistory';
  public static AddRevokeCookiesConsentDataWithHistory = 'V1/GDPR/AddRevokeCookiesConsentDataWithHistory';

  //veriDail
  public static GET_VERIDIAL_SUPPLIER_SPEND = 'Common/GetVeridailSupplierSpend';
  public static GET_VERIDIAL_SUPPLIER_SPEND_DETAILS = 'Common/GetVeridailSupplierSpendDetails';
  public static VERIDIAL_SUPPLIER_DETAILS = 'Common/GetVeridialSupplierMonthlyDetailsFields';
  public static GET_SUPPLIER_DETAILS = 'Common/GetSupplierDetails';
  public static GET_SUPPLIER_GROUP_DETAILS = 'Common/GetSupplierGroupDetails';

  //Fill Rate by Vendor
  public static GET_FILL_RATE_BY_VENDOR_SUPPLIER_SPEND = 'Common/GetFillRatebyVendorTotal';
  public static GET_FILL_RATE_BY_VENDOR_SUPPLIER_SPEND_DETAILS = 'Common/GetFillRatebyVendorByDate';
  public static FILL_RATE_BY_VENDOR_SUPPLIER_DETAILS = 'Common/GetFillRatebyVendorBYSOWMonthly';
  public static FILL_RATE_BY_VENDOR_CONTINGENT_SUPPLIER_DETAILS = 'Common/GetFillRatebyVendorBYcontingentMonthly';

  public static GetCandidateListForMessaging = 'Messaging/GetCandidateListForMessaging';
  public static SendMessage = 'Messaging/SendMessage';
  public static GetMessageListByCandidateId = 'Messaging/GetMessageListByCandidateId';

  public static GetCandidateListFromBullhorn = 'Bullhorn/GetBullhornJobseekerData'
  public static ProcessThirdPartySyncData = 'Bullhorn/StartThirdPartyDataSync'
    //Report by Graph
    public static RECRUITER_BASED_ON_INTERVIEW= 'Common/recruiterBasedonInterview';
    public static RECRUITER_BASED_ON_RECOMMENDATION= 'Common/recruiterBasedonRecommendation';
    public static COUNTRY_BASED_ON_INTERVIEW= 'Common/countryBasedonInterview';

    
    public static SaveMsteamsPopupStatus = 'PopupConfig/SaveMSTPopup';

    //Report Verified Candidate
    public static CANDIDATE_VERIFIED_VIDEO_AUDIO_DETAILS= 'Report/candidateVerifiedVideoAudioDetails';
    public static INTERVIEW_CANDIDATE_VERIFIED_MONTHLY_AUDIO_DETAILS = 'Report/candidateVerifiedAudioVedioDetails';
    public static INTERVIEW_CANDIDATE_VERIFIED_AUDIO_DETAILS = 'Report/candidateVerifiedAudioDetails';
    public static INTERVIEW_CANDIDATE_VERIFIED_VIDEO_DETAILS = 'Report/candidateVerifiedVideoDetails';
    public static INTERVIEW_CANDIDATE_VERIFIED_MONTHLY_AUDIO_VIDEO_DETAILS = 'Report/candidateVerifiedAudioVedioDetails';
   
    
   
    public static GetBHCandidateEmailByBullhornId='Candidate/GetBHCandidateEmailByBullhornId';
    public static InitiateBHSendAppointmentAPI='Bullhorn/InitiateBHSendAppointmentAPI';

    //Report Candidate Considered
    public static GET_INTERVIEW_TYPE_List = 'Report/GetInterviewTypeList'
    public static GET_RECOMMENDATION_TYPE_List = 'Report/GetRecommendationList'
    public static CANDIDATE_GRID_DATA_DETAILS='Report/consolidateCandidateGridData';
    public static CANDIDATE_RECOMMENDATION_GRAPH_DETAILS= 'Report/consolidateCandidateRecommendationGraph';
    public static CANDIDATE_RECOMMENDATION_MONTHLY_GRAPH_DETAILS= 'Report/consolidateCandidateMonthlyGraph';
    public static CANDIDATE_DETAILS= 'Report/consolidateCandidateReport';
    public static CANDIDATE_RECURITER_DETAILS= 'Report/CandidateRecuriterConsolidateReport';
    public static CANDIDATE_DETAILS_DOWNLOAD= 'Report/consolidateCandidateDownloadReport';

    //Report Candidate Interview Considered
    public static CANDIDATE_INTERVIEW_RECOMMENDATION_GRAPH_DETAILS= 'Report/consolidateCandidateRecommendationGraphReport';
    public static CANDIDATE_INTERVIEW_RECOMMENDATION_MONTHLY_GRAPH_DETAILS= 'Report/consolidateCandidateMonthlyGraphReport';
    public static CANDIDATE_INTERVIEW_DETAILS= 'Report/consolidateCandidateRecommendationReport';
    public static CANDIDATE_INTERVIEW_DOWNLOAD_DETAILS= 'Report/DownloadconsolidateCandidateRecommendationReport';
    public static CANDIDATE_INTERVIEW_DETAILS_DOWNLOAD= 'Report/consolidateCandidateRecommendationdownloadReport';
    public static CANDIDATE_INTERVIEW_DOWNLOAD_CONSIDERED_DETAILS= 'Report/consolidateDownloadCandidateRecommendationReport';
   
    

  //Questionier
  public static Questionier_Save = 'Questionier/SaveQuestionier';
  public static Questionier_Update = 'Questionier/UpdateQuestionier';
  public static Questionier_Detail = 'Questionier/GetQuestionier';
  public static Questionier_DetailWeb = 'Questionier/GetQuestionierWeb';
  public static QuestionierTemplateList = 'Questionier/GetRecruiterTemplate';
  public static DeleteQuestion = 'Questionier/DeleteQuestion';
  public static DeleteOption = 'Questionier/DeleteOption';
  public static DeleteTemplate = 'Questionier/DeleteTemplate';
  public static Check_Template = 'Questionier/GetSearchTemplate'  // with no auth
  public static SendQuestionier = 'Questionier/SendQuestionier';
  public static SaveQuestionierAnswers = 'Questionier/SaveQuestionierAnswers';
  public static GetCandidateQuestionierAnswerSheet = 'Questionier/GetCandidateListQuestionierAnswerSheet';
  public static UnsubscribeCommunication = 'Common/UnsubscribeCommunication';
  public static GetCandidateQuestionnaireStatus = 'Questionier/GetCandidateQStatus';

  public static GETCANDIDATESTATUSLIST = 'Candidate/GetCandidateStatusList';

  public static GETCANDIDATENOTESLIST = 'Candidate/GetNotesByCandidateId';

  public static SAVECANDIDATENOTES = 'Candidate/SaveNotes'

  public static UPDATECANDIDATESTATUS = 'Candidate/UpdateCandidateStatus'

  public static GETCANDIDATESTATUS = 'Candidate/GetCurrentCandidateStatus';

  public static GETCANDIDATESTATUSHISTORY = 'Candidate/GetCandidateStatusHistory';

  public static UPDATECANDIDATEIMAGEORRESUME = 'Candidate/UpdateCandidateImage';

  public static GETCANDIDATEDATABYID = 'Candidate/GetCandidateDataById';

  public static GETCANDIDATEEMAILTEMPLATELIST = 'Candidate/GetEmailTemplatesList';

  public static SENDCANDIDATEEMAIL = 'Candidate/SendCandidateMail';

  public static GETCANDIDATEEMAILHISTORY = 'Candidate/GetCandidateEmails';

  public static GETCANDIDATEINTERVIEWSLIST = 'Candidate/GetCandidateInterviews';

  //dashboard
  public static GET_DASHBOARD_CANDIDATE_INTERVIEW_DETAILS = "Common/GetDashboardInterviewDetails";
  public static SendVKScoreCardPDFToBullhornCandidate = 'Bullhorn/SendVKScoreCardPDFToBullhornCandidate';
  public static CANDIDATE_DASHBOARD_PROFILE_FUNNEL_REPORT= 'Common/dashboardProfileFunnelReport';

   //Candidate Ageing Report
   public static CANDIDATE_INTERVIEW_AGEING_DATA_DETAILS = 'Report/CandidateAgeingDetails';
   public static CANDIDATE_INTERVIEW_AGEING_GRAPH_DATA_DETAILS = 'Report/CandidateAgeingGraphDetails';
   public static CANDIDATE_AGEING_DETAILS = 'Report/CandidateAgeingReport';

  // Candidate Intro
  public static GETCANDIDATEVIDEOTOKEN = 'ScheduleVideo/GetCandidateTokenDetails';

  public static AddBHCandidate = 'bullhorn/addCandidate';
  public static AddBHCandidateV1 = 'Bullhorn/InitiateBHCandidateAddToVKAPI';

  //get Participant Details
  public static VerifyParticipantEmail = 'ScheduleVideo/VerifyParticipantEmail';

  public static GETLOOKUPDATA = 'Common/GetLookupData';
  public static UPDATEUSERCONFIG = 'v1/Account/UpdateUserConfig';

   //Report Analytics Dashboard
   public static GET_SOURCE_TYPE_List = 'Report/GetSourceTypeList';
   public static GET_PANEL_List = 'Report/GetPanelDetailsList';
   public static GET_JobId_List = 'Report/GetJobIdList';
   public static DASHBOARD_INTERVIEW_DETAILS = 'Report/GetDashboardInterviewViewCountDetails';
   public static GET_DASHBOARD_INTERVIEW_VIEW_List = 'Report/GetDashboardDetails';
   public static ANALYTICS_DASHBOARD_BAR_GRAPH = 'Report/GetAnalyticsInterviewGraphDataDetails';
   public static ANALYTICS_INTERVIEW_AGEING_GRAPH_DATA_DETAILS = 'Report/GetAnalyticCandidateAgeingGraphDetails';
   public static ANALYTICS_CANDIDATE_BY_RECOMMANDATION = 'Report/GetAnalyticsDashboardRecommendationDetails';
   public static ANALYTICS_DASHBOARD_PRIMARY_SKILL = 'Report/GetAnalyticsDashboardPrimarySkillDetails';
   public static ANALYTICS_DASHBOARD_ATTRIBUTE_LIST = 'Report/GetAnalyticsDashboardAttributeDetails';
   public static ANALYTICS_DASHBOARD_SOURCE_TYPE_DETAILS = 'Report/GetAnalyticsDashboardSourceTypeDetails';
   public static ANALYTICS_DASHBOARD_OUT_OF_INTERVIEW = 'Report/GetAnalyticsDashboardOutofInterviewDetails';
   public static ANALYTICS_DASHBOARD_VERIFIED_MONTHLY_AUDIO_DETAILS = 'Report/GetAnalyticscandidateVerifiedAudioVedioDetails';
   public static ANALYTICS_INTERVIEW_STATUS = 'Report/GetAnalyticsDashboardInterviewStages';
   public static ANALYTICS_DASHBOARD_CANDIDATE_VERIFIED_VIDEO_AUDIO_DETAILS= 'Report/AnalyticsDashboardcandidateVerifiedVideoAudioDetails';
   public static ANALYTICS_DASHBOARD_CANDIDATE_INTERVIEW_AGEING_DATA_DETAILS = 'Report/AnalyticsDashboardCandidateAgeingDetails';

  // GetAnalyticsDashboardRecommendationDetails
  public static InbetweenInterviewCheck = 'Common/GetCheckInterviwerScheduleRule'
}