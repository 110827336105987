<div class="modal-header">
    <h4 class="modal-title">{{ title }}</h4>
      <button type="button" class="close" aria-label="Close" (click)="dismiss()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      {{(message?.length>100)?(message | slice:0:100)+'..':(message)}}
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-danger" (click)="decline()">No</button>
      <button type="button" class="btn btn-primary" (click)="accept()">Yes</button>
</div>
