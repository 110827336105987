export const HeaderNav = [
    { title: 'Dashboard', icon: 'assets/images/dashboard-icon.png', route: '/' },
    //{ title: 'Profile Creation', icon: 'assets/images/profile-creation.png', route: '/profile-creation' },
    { title: 'Candidate', icon: 'assets/images/profile-verification.png', route: '/profile-verification' },
    { title: 'Video Schedule', icon: 'assets/images/profile-verification.png', route: '/all-interviews' },
    { title: 'Score Card', icon: 'assets/images/assesment-icon.png', route: '/assessments' },
    { title: 'Jobs', icon: 'assets/images/assesment-icon.png', route: '/Fullfillment-Calculator' },
    { title: 'Analytics', icon: 'assets/images/assesment-icon.png', route: '/analytics' }
    // { title: 'Video Session', icon: 'assets/images/assesment-icon.png', route: '/video-session' }
    //{ title: 'Messaging', icon: 'assets/images/profile-verification.png', route: '/messaging'  }
]
