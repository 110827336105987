
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import * as moment from 'moment-timezone';
import { ApiService } from './core/api.service';
import { apiPaths } from '../setting/apiPath';
import { time } from 'console';
import * as moment2 from 'moment';
import { SnackbarService } from 'src/app/services/snackBar/snackbar.service';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  //hideMenu:boolean=false;
  messageSource = new BehaviorSubject(false);
  //currentMessage = this.messageSource.asObservable();

  constructor(private http: ApiService, private snackBar: SnackbarService) { }

  changeMenubehavior(message: boolean) {
    //console.log('call from service:',message);
    this.messageSource.next(message)
  }
  
  // DISABLE BLANK SPACE 
  validateBlankSpace(e: { currentTarget: { selectionStart: any; }; which: number; preventDefault: () => void; }) {
    const startPos = e.currentTarget.selectionStart;
    if ((e.which === 32 && startPos === 0) || (e.which === 13 && startPos === 0)) {
      e.preventDefault();
    }
  }

  //  DISABLE PLUSH SIGN 
  omitPlusValidate(event: { charCode: any; }): Observable<any> {
    const k = event.charCode;
    if (k != 43)
      return;
  }

  //function to get the first letter from first and last name in capital
  getFirstLetterFromString(input: string) {
    
    if (input != null && input != '') {

      var matches = input.match(/\b(\w)/g);
      if (matches != null) {
        var output = matches.join('');
  
        return output.toUpperCase().substring(0, 2);
      }
    }
   
    return '';
  }

  //function to get the duration in hours for specified interview date wrt current date
  getDurationInHours(interviewDateStr: any) {
    const dbTimeZone = `${environment.CurrentTimeZone}`;
    //console.log(" interview date " + interviewDateStr);
    let strinterviewDate = moment(interviewDateStr, 'YYYY-MM-DD hh:mm a').format('YYYY-MM-DD hh:mm');
    //console.log(" interview date string " + strinterviewDate);
    let interviewDate = moment(strinterviewDate, 'YYYY-MM-DD hh:mm a').format('YYYY-MM-DD hh:mm a');
    //console.log(" interview date1 " + interviewDate);
    //let currentutcDateTime = moment.utc().format('YYYY-MM-DD hh:mm a');
    //console.log(" currentutcdatetime "+currentutcDateTime);
    let currentDateTime = moment.tz(dbTimeZone).format('YYYY-MM-DD hh:mm a');
    //console.log(" currentdatetime " + currentDateTime);
    let currentDate = moment.tz(currentDateTime, 'YYYY-MM-DD hh:mm a', dbTimeZone);
    //console.log(" currentDate " + currentDate);
    var duration = moment.duration(currentDate.diff(interviewDate)).asHours();
    return duration;
  }
  
  getTimeZones(): Observable<any> {
    
    return this.http.GET(`${apiPaths.GET_TIME_ZONE}`);
  }

  getLocalTimezone() {
    // Durgesh_20210826_MozillaTimezone
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let newtimezone = timezone;
    if (timezone === 'Asia/Kolkata') {
      newtimezone = 'Asia/Calcutta';
    }
    //const currentTime = moment().tz(timezone).format();
    //console.log('local timezone: ',newtimezone);
    //console.log('local current time: ',currentTime);
    return newtimezone;
  }

  getUTCDateTime(inputDate) {
    if (inputDate) {
      var date = new Date(inputDate);
      //console.log('input date',inputDate,moment2.utc(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())).format('YYYY-MM-DD HH:mm:ss'));
      return moment2.utc(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())).format('YYYY-MM-DD HH:mm:ss');
    }
    else {
      return '';
    }
  }

  getUTCDateTime1(inputDate, timezone) {
    if (inputDate) {
      var date = new Date(inputDate);
      let interviewDate = new Date(new Date(inputDate).toString().replace(/GMT.+/g, 'GMT'));
      moment.locale('en-us');
      //let interviewDate = moment(inputDate, 'YYYY-MM-DD hh:mm a').utc().format('YYYY-MM-DD hh:mm:ss A');
      //moment(dt, dtFormat).utc().format('YYYY-MM-DD hh:mm:ss A');
      //console.log('input  date',inputDate,moment2.utc(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(),date.getHours(),date.getMinutes(),date.getSeconds())).format('YYYY-MM-DD HH:mm:ss'));
      //console.log('input utc date',interviewDate);
      var date2 = moment(inputDate).format('YYYY-MM-DD hh:mm:ss A');
      //console.log('date2',date2);
      //return  moment().tz(interviewDate,'YYYY-MM-DD HH:mm:ss',timezone);
      return date2;
    }
    else {
      return '';
    }
  }

  getFormatteddate(inputDate) {
    // console.log('inputdate',inputDate);
    // let newDate= new Date(inputDate);
    // newDate.setMinutes(newDate.getMinutes() + newDate.getTimezoneOffset());
    // console.log('newdate',newDate);
    return inputDate;
  }

  hideHeaderLoginCss() {
    document.getElementById('appHome').removeAttribute('class');
    document.getElementById('appHome').setAttribute('class', 'wrapper nonlogin');
  }
  convertDateIntoUtc(value) {
    if (value) {
      return moment2.utc(value).format('YYYY-MM-DD HH:mm:ss');
    }
    else {
      return '';
    }
  }

  
  omit_special_charComment(e: any) {
    try {
      let clipboardData = e.clipboardData;
      let pastedText = clipboardData.getData('text');
      if (/^[a-zA-Z0-9\/?()-_,.;:"'!@#$&*=+<>\s]*$/.test(pastedText)) {
        return true;
      } else {
        e.preventDefault();
        this.snackBar.openSnackBar('Please do not use special characters ` ~ % ^ [ ] { } | \ ');
        return false;
      }
    } catch (e) {
    }
  }
  
  
  omit_validate_specialchar(event: { charCode: any; }, e) {
    const k = event.charCode;
    if (k == 37 || k == 91 ||  k == 93 || k == 94 || k == 96 || k == 123 || k == 124 || k == 125 || k == 126) {
      this.snackBar.openSnackBar('Please do not use special characters ` ~ % ^ [ ] { } | ');
      e.preventDefault();
      return false
    }
    else {
      return true;
    }
  }

  // Only AlphaNumeric
  keyPressAlphaNumeric(event) {

    var inp = String.fromCharCode(event.keyCode);

    if (/^[A-Za-z0-9]*$/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }
  
  omit_special_char(event: { charCode: any }, e) {
    const k = event.charCode;
    if (
      (k > 64 && k < 91) ||
      (k > 96 && k < 123) ||
      k === 8 ||
      k === 32 ||
      (k >= 48 && k <= 57)
    ) {
      return true;
    } else {
      if (k != 58 && k != 44 && k != 45 && k != 46 && k != 47) {
        e.preventDefault();
        return false;
      } else {
        return true;
      }
    }
  }

}