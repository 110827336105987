import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SavedProfileDetailEntity } from 'src/app/model/profileVerification.model';
import * as CryptoJS from 'crypto-js';
import { Key } from 'protractor';
import { ApiService } from '../core/api.service';
import { apiPaths } from 'src/app/setting/apiPath';
const key = 'U2FsdGVkX1+iX5Ey7GqLND5UFUoV0b7rUJ2eEvHkYqA=';
@Injectable({
  providedIn: 'root'
})
export class DataService {
  constructor(private http: ApiService,) { }

  private document = new BehaviorSubject<boolean>(false);
  castDocument = this.document.asObservable();

  private interview = new BehaviorSubject<number>(0);
  interviewerId = this.interview.asObservable();

  private logedInUser = new BehaviorSubject<string>(null);
  logedUser = this.logedInUser.asObservable();


  editData(documentVal:boolean){
    this.document.next(documentVal); 
  }

  setInterviewerId(Id:number){
    localStorage.setItem('InterviewerId',this.set(Id.toString()));
    this.interview.next(Id);
  }

  getInterviewerId(){
    return this.get(localStorage.getItem('InterviewerId'));
  }
  setLogedInUser(user:string){
    localStorage.setItem('logedUser',this.set(user));
    this.logedInUser.next(user);
  }
  getLogedInUser(){
    return this.get(localStorage.getItem('logedUser'));
  }
  setSubscriberId(subscriberid:string){
    localStorage.setItem("subscriberId",this.set(subscriberid));
  }

  getCandidateId(){
    return this.get(localStorage.getItem("candidateId"))
  }

  setCandidateId(candidateId:string){
    localStorage.setItem("candidateId",this.set(candidateId));
  }

  getSubscriberId(){
    return this.get(localStorage.getItem("subscriberId"))
  }
getCurrentCandidateDdata(){
  return localStorage.getItem("CurrentCandidateData");
}
  private  set(value){
    return CryptoJS.Rabbit.encrypt(value, key).toString();
  }

  private  get(value){
    return CryptoJS.Rabbit.decrypt(value, key).toString(CryptoJS.enc.Utf8);
  }

  filterinterviewbySubscriberIDSubmit(data: any) {
    return this.http.POST(`${apiPaths.UPDATE_INTERVIEWER_BY_ID}`, data )
  }

}
