import { Routes } from '@angular/router';
import { AuthGuard } from '../gaurds/auth.guard';

export const routes: Routes = [

    {
        path: '',
        loadChildren: () => import('../components/recruiter/home/home.module').then(m => m.HomeModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'IntvLogin/:encID',
        loadChildren: () => import('../components/feedback/interviewer-assessment-form.module').then(m=>m.InterviewerAssessmentFormModule)
    },
    {
        path: 'login',
        loadChildren: () => import('../components/login/login.module').then(m => m.LoginModule)
    },
    {
        path: 'assessments',
        loadChildren: () => import('../components/recruiter/assessments/assessments.module').then(m => m.AssessmentsModule)
        ,canActivate: [AuthGuard]
    },
    {
        path: 'meet-without-video/:videoaccesscode',
        loadChildren: () => import('../components/no-video-session/no-video-session.module').then(m => m.NoVideoSessionModule)
    },
    {
        path: 'dashboard',
        loadChildren: () => import('../components/report/dashboard-report/dashboard-report.module').then(m => m.DashboardReportModule)
        ,canActivate: [AuthGuard]
    },
    {
        path: 'analytics',
        loadChildren: () => import('../components/report/dashboard-reports/dashboard-reports.module').then(m => m.DashboardReportsModule)
       ,canActivate: [AuthGuard]
    },
	{
        path: 'recommendation',
        loadChildren: () => import('../components/report/recommendation-details/recommendation-details.module').then(m => m.RecommendationDetailsModule)
       ,canActivate: [AuthGuard]
    },
    {
        path: 'reportbygraph',
        loadChildren: () => import('../components/report/report-by-graph/report-by-graph.module').then(m => m.ReportByGraphModule)
       ,canActivate: [AuthGuard]
    },
    {
        path: 'candidateVerifiedReport',
        loadChildren: () => import('../components/report/candidate-verified-report/candidate-verified-report.module').then(m => m.CandidateVerifiedReportModule)
       ,canActivate: [AuthGuard]
    },
    {
        path: 'candidateConsideredReport',
        loadChildren: () => import('../components/report/candidate-considered-report/candidate-considered-report.module').then(m => m.CandidateConsideredReportModule)
       ,canActivate: [AuthGuard]
    },
    {
        path: 'candidateInterviewConsideredReport',
        loadChildren: () => import('../components/report/candidate-interview-considered-report/candidate-interview-considered-report.module').then(m => m.CandidateInterviewConsideredReportModule)
       ,canActivate: [AuthGuard]
    },
    {
        path: 'candidateAgeingReport',
        loadChildren: () => import('../components/report/candidate-ageing-report/candidate-ageing-report.module').then(m => m.CandidateAgeingReportModule)
       ,canActivate: [AuthGuard]
    },
    {
        path: 'analyticsDashboardDetails',
        loadChildren: () => import('../components/report/analytics-dashboard-details/analytics-dashboard-details.module').then(m => m.AnalyticsDashboardDetailsModule)
       ,canActivate: [AuthGuard]
    },
    {
        path: 'veridial',
        loadChildren: () => import('../components/report/veridial/veridial.module').then(m => m.VeridialModule)
       ,canActivate: [AuthGuard]
    },
    {
        path: 'vdFillRatebyVendor',
        loadChildren: () => import('../components/report/verisource-fill-rate-by-vendor/verisource-fill-rate-by-vendor.module').then(m => m.VerisourceFillRateByVendorModule)
       ,canActivate: [AuthGuard]
    },
    {
        path: 'analyticsMailSchedules',
        loadChildren: () => import('../components/report/analytics-mail-schedule/analytics-mail-schedules.module').then(m => m.AnalyticsMailSchedulesModule)
       ,canActivate: [AuthGuard]
    },
    {
        path: 'interviwers',
        loadChildren: () => import('../components/report/interviwer-details/interviwer-details.module').then(m => m.InterviwerDetailsModule)
       ,canActivate: [AuthGuard]
    },
    {
        path: 'Fullfillment-Calculator',
        loadChildren: () => import('../components/recruiter/fullfillment-calculator/fullfillment-calculator.module').then(m => m.FullfillmentCalculatorModule)
        ,canActivate: [AuthGuard]
    },
    {
        path: 'technical-assesment/:id',
        loadChildren: () => import('../components/recruiter/technical-assesment/technical-assesment.module').then(m => m.TechnicalAssesmentModule)

    },
    {
        path: 'AssessmentReport/:access_code',
        loadChildren: () => import('../components/recruiter/technical-assesment-details/technical-assesment-details.module').then(m => m.TechnicalAssesmentDetailsModule)

    },
    {
        path: 'profile-creation',
        loadChildren: () => import('../components/recruiter/profile-creation/profile-creation.module').then(m => m.ProfileCreationModule)
        ,canActivate: [AuthGuard]
    },
    {
        path: 'interviewer-details',
        loadChildren: () => import('../components/recruiter/interviewer/interviewer.module').then(m => m.InterviewerModule)
        ,canActivate: [AuthGuard]
    },
    // {
    //     path: 'interview-details',
    //     loadChildren: () => import('../components/recruiter/interviewer/interviewer-details/interviewer-details.module').then(m => m.InterviewerDetailsModule)
    //     ,canActivate: [AuthGuard]
    // },
    {
        path: 'profile-verification',
        loadChildren: () => import('../components/recruiter/profile-verification/profile-verification.module').then(m => m.ProfileVerificationModule)
        ,canActivate: [AuthGuard]
    },
    {
        path: 'messaging',
        loadChildren: () => import('../components/recruiter/messaging/messaging.module').then(m => m.MessagingModule)
       // ,canActivate: [AuthGuard]
    },
    //{
      //path: 'mockup',
      //loadChildren: () => import('../components/recruiter/messaging/messaging.module').then(m => m.MessagingModule)
      // ,canActivate: [AuthGuard]
    //},
    {
        path: 'all-interviews',
        loadChildren: () => import('../components/recruiter/allinterviews/allinterviews.module').then(m => m.AllinterviewsModule)
        ,canActivate: [AuthGuard]
    },
    {
        path: 'candidate-detail/:id',
        loadChildren: () => import('../components/recruiter/candidate-detail/candidate-detail.module').then(m => m.CandidateDetailModule)
        ,canActivate: [AuthGuard]
    },
    {
        path: 'scorecard-detail/:id',
        loadChildren: () => import('../components/recruiter/assessments/edit-form/edit-form.module').then(m => m.EditAssessmentsModule)
        ,canActivate: [AuthGuard]

    },
    {
        path: 'create-profile',
        loadChildren: () => import('../components/recruiter/profile-creation/create-profile/create-profile.module').then(m => m.CreateProfileModule)
        ,canActivate: [AuthGuard]
    },
    {
        path: 'CreateCandidateProfile/ProfileForm/:id/:language/:token_id',
        loadChildren: () => import('../components/recruiter/profile-creation/send-create-profile/send-create-profile.module').then(m => m.SendCreateProfileModule)
    },
    {
        path: 'completed',
        loadChildren: () => import('../components/recruiter/profile-creation/completed/completed.module').then(m => m.CompletedModule)
    },
    {
        path: 'Verify/StatusUpdate/:token_id',
        loadChildren: () => import('../components/recruiter/candidate-detail/verify-reference/success/success.module').then(m => m.SuccessModule)
    },
    {
        path: 'CreateCandidateProfile/ProfileForm/:id/:token_id',
        loadChildren: () => import('../components/recruiter/profile-creation/send-create-profile/send-create-profile.module').then(m => m.SendCreateProfileModule)
    },
    {
        path: 'CCP/:id/:language/:token_id',
        loadChildren: () => import('../components/recruiter/profile-creation/send-create-profile/send-create-profile.module').then(m => m.SendCreateProfileModule)
    },
    {
        path: 'CCP/:id/:token_id',
        loadChildren: () => import('../components/recruiter/profile-creation/send-create-profile/send-create-profile.module').then(m => m.SendCreateProfileModule)
    },
    {
        path: 'CUC/:CandidateId/:token_id',
        loadChildren: () => import('../components/common/UnsubscribeCommunication/UnsubscribeCommunication.module').then(m => m.UnsubscribeCommunicationModule)
    },
    {
        path: 'CQQ/:CandidateId/:TemplateId/:token_id',
        loadChildren: () => import('../components/recruiter/QuestionnierSheet/QuestionnierSheet.component.module').then(m => m.QuestionnierSheetModule)
    },
    {
        path: 'VideoScheduleEmailStatus/Update/:accesscode/:status',
        loadChildren: () => import('../components/recruiter/candidate-detail/video-screen/accept-reject-video-email/accept-reject-video-email.module').then(m => m.AcceptRejectVideoEmailModule)
    },
    {
        path: 'VideoScheduleEmailStatus/Update/:accesscode/:status/:InterViewId',
        loadChildren: () => import('../components/recruiter/candidate-detail/video-screen/accept-reject-video-email/accept-reject-video-email.module').then(m => m.AcceptRejectVideoEmailModule)
    },
    {
        path: 'video-session/:videoaccesscode',
        loadChildren: () => import('../components/video-session/video-session.module').then(m => m.VideoSessionModule)
    },

    {
        path: 'meeting/meetingclosed',
        loadChildren: () => import('../components/video-session/meeting-closed/meeting-closed.module').then(m => m.MeetingClosedModule)
    },
    {
        path: 'meeting/meetingleave',
        loadChildren: () => import('../components/video-session/meeting-leave/meeting-left.module').then(m => m.MeetingLeftModule)
    },
    {
        path: 'meeting/LinkInUseMessage',
        loadChildren: () => import('../components/video-session/link-used/link-used.module').then(m => m.LinkUsedModule)
    },
    {
        path: 'dice',
        loadChildren: () => import('../components/recruiter/home/dice/dice.module').then(m => m.DiceModule)
        ,canActivate: [AuthGuard]
    },

    {
        path: 'deployment',
        loadChildren: () => import('../components/common/deployment/deployment.module').then(m => m.DeploymentModule)
    },
    {
    path: 'cbie',
    loadChildren: () => import('../components/cbie/cbie.module').then(m => m.CbieModule)
    },
    {
        path: '**',
        loadChildren: () => import('../components/common/not-found/not-found.module').then(m => m.NotFoundModule)
    },
    {
        path: 'resetPassword',
        loadChildren: () => import('../components/recruiter/home/reset-password/reset-password.module').then(m => m.ResetPasswordModule)
    }
]

