import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})

export class EncrDecrService {
  constructor() { }
  //Manu Datta: 20210406, Encryption
  //The set method is use for encrypt the value.
  set(keys, value, remSpclChar=false){
    // var key = CryptoJS.enc.Utf8.parse(keys);
    // var iv = CryptoJS.enc.Utf8.parse(keys);
    // var encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(value.toString()), key,
    // {
    //     keySize: 128 / 8,
    //     iv: iv,
    //     mode: CryptoJS.mode.CBC,
    //     padding: CryptoJS.pad.Pkcs7
    // });

    // return encrypted.toString();

    var keySize = 256;
    var salt = CryptoJS.lib.WordArray.random(16);
    var key = CryptoJS.PBKDF2(keys, salt, {
        keySize: keySize / 32,
        iterations: 100
    });
    
    var iv = CryptoJS.lib.WordArray.random(128 / 8);
    
    var encrypted = CryptoJS.AES.encrypt(value, key, {
        iv: iv,
        padding: CryptoJS.pad.Pkcs7,
        mode: CryptoJS.mode.CBC
    });
    
    var result = CryptoJS.enc.Base64.stringify(salt.concat(iv).concat(encrypted.ciphertext));
    if(remSpclChar){
      result = result.toString().replace('+','xMl3Jk').replace('/','Por21Ld').replace('=','Ml32');
      result = result.toString().replace('+','xMl3Jk').replace('/','Por21Ld').replace('=','Ml32');
      result = result.toString().replace('+','xMl3Jk').replace('/','Por21Ld').replace('=','Ml32');
      result = result.toString().replace('+','xMl3Jk').replace('/','Por21Ld').replace('=','Ml32');
      result = result.toString().replace('+','xMl3Jk').replace('/','Por21Ld').replace('=','Ml32');
    }
    
    return result;
  }

  //The get method is use for decrypt the value.
  get(keys, value){
    // var key = CryptoJS.enc.Utf8.parse(keys);
    // var iv = CryptoJS.enc.Utf8.parse(keys);
    // var decrypted = CryptoJS.AES.decrypt(value, key, {
    //     keySize: 128 / 8,
    //     iv: iv,
    //     mode: CryptoJS.mode.CBC,
    //     padding: CryptoJS.pad.Pkcs7
    // });

    // return decrypted.toString(CryptoJS.enc.Utf8);

    var key = CryptoJS.enc.Utf8.parse(keys);                             
    var iv = CryptoJS.lib.WordArray.create([0x00, 0x00, 0x00, 0x00]);  
    var decrypted = CryptoJS.AES.decrypt(value, key, {iv: iv}); 
  return decrypted.toString(CryptoJS.enc.Utf8);   
  }
}