<div class="wrapper nonlogin"  id="appHome" >
  <!-- [ngClass]="{'nonlogin': islogin}"  [ngClass]=" (isUser=='false')?'login':'nonlogin'"-->
  <!-- App Header-->
  <app-header></app-header>
  <div class="content">
    <!-- Loader -->
    <div class="mainloader">
      <ngx-loading-bar color="#f74d41"></ngx-loading-bar>
    </div>
   
      <router-outlet (activate)="onActivate($event)"></router-outlet>
  </div>
  <!-- App Footer -->
  <app-footer></app-footer>
  <!---->
</div>
