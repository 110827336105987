
<nav class="navbar navbar-expand-lg header" *ngIf="!isUser&&!hideMenu">
  <app-logo></app-logo>
<!--  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarHeader" aria-controls="navbarHeader" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse navigation" id="navbarHeader" *ngIf="!isUser&&!hideMenu">
    <ul class="navbar-nav navbar-top ml-auto">
        <li class="nav-item">
            <a class="nav-link" href="/support">Support</a>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            Company
          </a>
          <div class="dropdown-menu" aria-labelledby="navbarDropdown">
            <a class="dropdown-item" href="/about">About Us</a>
            <a class="dropdown-item" href="/careers">Careers</a>
          </div>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            Library
          </a>
          <div class="dropdown-menu" aria-labelledby="navbarDropdown">
            <a class="dropdown-item" href="/blog">Blog</a>
          </div>
        </li>
        <li class="nav-item">
            <a class="nav-link" href="/login"><i class="fas fa-user"></i> Login</a>
        </li>
    </ul>
    <ul class="navbar-nav ml-auto">
      <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" href="#" id="productDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          Products
        </a>
        <div class="dropdown-menu" aria-labelledby="productDropdown">
          <ul>
            <li>
              <a href="/products/veriklick">
                <h4>Veri<span class="text-red">Klick</span></h4>
                <span class="block">Recruiting Platform</span>
              </a>
            </li>
            <li>
              <a href="/products/veridial">
                <h4>Veri<span class="text-red">Dial</span></h4>
                <span class="block">Recruiting Dialer</span>
              </a>
            </li>
            <li>
              <a href="/products/verisource">
                <h4>Veri<span class="text-red">Source</span></h4>
                <span class="block">Candidate Sourcing</span>
              </a>
            </li>

          </ul>
        </div>
      </li>
      <li class="nav-item">
          <a class="nav-link" href="/pricing">Pricing</a>
      </li>
      <li class="nav-item">
          <a class="nav-link" href="/demo">Demo</a>
      </li>
      <li class="nav-item nav-btn">
          <a class="btn btn-red" href="/get-started">Get Started</a>
      </li>
    </ul>
  </div>-->
</nav>

<nav class="navbar navbar-expand-lg site-navbar" *ngIf="isUser&&!hideMenu">
  <app-logo></app-logo>
      <div class="ml-auto navigation">
        <div class="collapse navbar-collapse " id="navbarHeader">
          <ul class="navbar-nav align-items-center">
            <li class="nav-item" *ngFor="let data of headerNav">
              <a [routerLink]="data.route" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="nav-link">
                <!--<img [src]="data.icon">-->
                <span>{{data.title}}</span>
              </a>
            </li>
          </ul>
        </div>
      <ul class="navbar-nav navbar-right align-items-center d-flex flex-nowrap flex-row">
       <!--<li class="nav-item dropdown nav-notification">
          <a class="nav-link"  (click)="OpenlfMenu()" href="javascript:void(0)">
            <i class="material-icons">notifications</i>
            <span class="count">1</span>
          </a>
          
        </li>--> 
        <li class="nav-profile">
          <a [matMenuTriggerFor]="menu" class="nav-link">
            <span class="img"><img 
              [src]="(Img_Path == null || Img_Path == '')? '/assets/images/user-dummy.png' : Img_Path"
               alt="user-image" class="rounded-circle" style="height: 30px; width: 30px;"></span>
            <h5>
               {{userName}}
            </h5>  
            <span class="arrow"><i class="material-icons">arrow_drop_down</i></span>
          </a>
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="logout()">Logout</button>
            <button mat-menu-item (click)="resetPassword()">Change Password</button>
            <button mat-menu-item (click)="setUserConfiguration()">User Setting</button>
          </mat-menu>
        
        </li>
      </ul>

      <!-- Dashboard Dropdown -->
      <div class="dropdown dropdownTools">
        <button class="btn dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true"><svg focusable="false" viewBox="0 0 24 24"><path d="M6,8c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM12,20c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM6,20c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM6,14c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM12,14c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM16,6c0,1.1 0.9,2 2,2s2,-0.9 2,-2 -0.9,-2 -2,-2 -2,0.9 -2,2zM12,8c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM18,14c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM18,20c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2z"></path></svg></button>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
      <ul>
      
      <li>
      <div routerLink="/create-profile" class="box"><span class="icon"><svg xmlns="http://www.w3.org/2000/svg" width="204.75" height="234" viewBox="0 0 204.75 234">
        <g id="Group_1" data-name="Group 1" transform="translate(-764 -337.25)">
          <path id="Path_2" data-name="Path 2" d="M124.313-65.812,109.185-40.584,124.4,16.042l18.053-73.673A73.743,73.743,0,0,1,204.75,15.219,14.063,14.063,0,0,1,190.719,29.25H14.04A14.05,14.05,0,0,1,0,15.219,73.738,73.738,0,0,1,62.293-57.632L80.346,16.042,95.565-40.584,80.438-65.812Z" transform="translate(764 542)" fill="#ff69a5"></path>
          <path id="Path_1" data-name="Path 1" d="M43.875-146.25a58.5,58.5,0,0,1,58.5-58.5,58.5,58.5,0,0,1,58.5,58.5,58.5,58.5,0,0,1-58.5,58.5A58.5,58.5,0,0,1,43.875-146.25Z" transform="translate(764 542)" fill="#ffe7f1"></path>
        </g>
      </svg>
      </span><h6>Add Job Seeker</h6></div>
      </li><li>
      <div (click)="sendForm()" class="box"><span class="icon"><svg xmlns="http://www.w3.org/2000/svg" width="234" height="218.375" viewBox="0 0 234 218.375">
        <g id="Group_2" data-name="Group 2" transform="translate(-1148 -438.875)">
          <path id="Path_4" data-name="Path 4" d="M138.572-22.12A35.024,35.024,0,0,1,117-14.579a34.993,34.993,0,0,1-21.554-7.514L0-96.342V7.313A21.94,21.94,0,0,0,21.938,29.25H212.063A21.854,21.854,0,0,0,234,7.724V-96.342Z" transform="translate(1148 628)" fill="#e92b5f"></path>
          <path id="Path_3" data-name="Path 3" d="M190.125-190.125H43.875A14.546,14.546,0,0,0,29.25-175.911v83.774l75.182,58.454a20.487,20.487,0,0,0,25.164,0L204.75-92.138V-175.5A14.618,14.618,0,0,0,190.125-190.125Zm-36.562,87.339H80.438a7.313,7.313,0,0,1-7.312-7.312c0-4.039,3.274-6.9,7.313-6.9h73.125a7.238,7.238,0,0,1,7.313,6.9A7.082,7.082,0,0,1,153.563-102.786Zm0-29.25H80.438a7.239,7.239,0,0,1-7.312-6.9c0-4.039,3.274-6.9,7.313-6.9h73.125a7.238,7.238,0,0,1,7.313,6.9A6.993,6.993,0,0,1,153.563-132.036Z" transform="translate(1148 629)" fill="#f6aabf"></path>
        </g>
      </svg>
      
      </span><h6>Send link to User</h6>
      <form class="sendForm" [formGroup]="sendProfileForm" (ngSubmit)="onSubmit()" *ngIf="isShow">
        <div class="input-group mb-0">
            <div class="errorMsg w-100 mb-1 mt-1" *ngIf="isSubmitted && f.emailid.errors"> 
                <div *ngIf="f.emailid.errors.required">Email is required</div>
                <div *ngIf="f.emailid.errors.pattern">Email must be a valid email address</div>
            </div> 
            <input class="form-control" pattern="[a-zA-Z0-9._%+-]+@([a-zA-Z0-9-]{1,})+(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?$" maxlength="100" name="emailid" (keypress)="validateSpace($event)" formControlName="emailid" placeholder="Enter email" (keyup)="emailValidation($event)" (paste)="emailValidationOnPaste($event)"  required>   
            <div class="input-group-append">
                <button class="btn btn-primary" type="submit">Send</button>
            </div>
             
          </div>
    </form>
    </div>
      </li><li>
      <div routerLink="/assessments" class="box"><span class="icon"><svg xmlns="http://www.w3.org/2000/svg" width="292.101" height="241" viewBox="0 0 292.101 241">
        <path id="Path_9" data-name="Path 9" d="M160.875-7.312A7.313,7.313,0,0,1,153.563,0H80.438a7.312,7.312,0,0,1-7.312-7.312V-146.25H21.938A21.94,21.94,0,0,0,0-124.312V7.313A21.937,21.937,0,0,0,21.938,29.25H212.063A21.937,21.937,0,0,0,234,7.313V-29.25H160.875ZM47.531-4.113A4.114,4.114,0,0,1,43.418,0H29.707a4.114,4.114,0,0,1-4.113-4.113V-17.824a4.114,4.114,0,0,1,4.113-4.113H43.418a4.114,4.114,0,0,1,4.113,4.113Zm0-47.531a4.114,4.114,0,0,1-4.113,4.113H29.707a4.114,4.114,0,0,1-4.113-4.113V-65.355a4.114,4.114,0,0,1,4.113-4.113H43.418a4.114,4.114,0,0,1,4.113,4.113Zm0-47.531a4.114,4.114,0,0,1-4.113,4.113H29.707a4.114,4.114,0,0,1-4.113-4.113v-13.711A4.1,4.1,0,0,1,29.707-117H43.418a4.114,4.114,0,0,1,4.113,4.113v13.711ZM186.469-17.824a4.114,4.114,0,0,1,4.113-4.113h13.711a4.114,4.114,0,0,1,4.113,4.113V-4.113A4.114,4.114,0,0,1,204.293,0H190.582a4.114,4.114,0,0,1-4.113-4.113Zm75.547-66.681L213.251-157.63a7.245,7.245,0,0,0-6.079-3.245,7.3,7.3,0,0,0-6.083,3.256l-28.674,43.011L162.2-128.62a7.457,7.457,0,0,0-6.307-3,7.316,7.316,0,0,0-5.909,3L117.992-84.745a8.381,8.381,0,0,0-.215,7.644,6.814,6.814,0,0,0,6.124,3.976H255.526a7.318,7.318,0,0,0,6.449-3.861A6.5,6.5,0,0,0,262.016-84.505Z" transform="translate(0 211.75)" fill="#319cff" opacity="0.62"></path>
        <path id="Path_10" data-name="Path 10" d="M270.151-204.75H109.276a22.208,22.208,0,0,0-21.937,21.938v117a21.937,21.937,0,0,0,21.937,21.938H270.151a21.937,21.937,0,0,0,21.938-21.937v-117C292.5-194.928,282.674-204.75,270.151-204.75ZM138.526-175.5a14.624,14.624,0,0,1,14.625,14.625,14.624,14.624,0,0,1-14.625,14.625A14.879,14.879,0,0,1,123.9-160.875,14.879,14.879,0,0,1,138.526-175.5ZM262.382-76.964a7.3,7.3,0,0,1-6.444,3.839H123.9a6.814,6.814,0,0,1-6.124-3.976,7.3,7.3,0,0,1,.64-7.632l31.992-43.875a7.058,7.058,0,0,1,5.484-3.016,7.315,7.315,0,0,1,5.909,3l10.215,14.013,28.674-43.011a7.525,7.525,0,0,1,6.481-3.256,7.311,7.311,0,0,1,6.083,3.256l48.765,73.125A7.313,7.313,0,0,1,262.382-76.964Z" transform="translate(0 204.75)" fill="#319cff" opacity="0.3"></path>
      </svg>
      
      </span><h6>Assessments</h6></div>
      </li><li>
      <div routerLink="/create-profile" class="box"><span class="icon"><svg id="Group_3" data-name="Group 3" xmlns="http://www.w3.org/2000/svg" width="175.5" height="234" viewBox="0 0 175.5 234">
        <path id="Path_6" data-name="Path 6" d="M117-14.625v-58.5h58.5Zm7.313-175.5H51.188a7.334,7.334,0,0,1-7.312-7.312,7.334,7.334,0,0,1,7.313-7.312h73.125a7.313,7.313,0,0,1,7.313,7.313A7.323,7.323,0,0,1,124.313-190.125Zm0,29.25H51.188a7.334,7.334,0,0,1-7.312-7.312,7.334,7.334,0,0,1,7.313-7.312h73.125a7.313,7.313,0,0,1,7.313,7.313A7.323,7.323,0,0,1,124.313-160.875Zm0,29.25H51.188a7.334,7.334,0,0,1-7.312-7.312,7.334,7.334,0,0,1,7.313-7.312h73.125a7.334,7.334,0,0,1,7.313,7.313A7.334,7.334,0,0,1,124.313-131.625Z" transform="translate(0 248.625)" fill="#faceb0"></path>
        <path id="Path_5" data-name="Path 5" d="M117-29.25v58.5H21.938A21.937,21.937,0,0,1,0,7.313V-182.812A21.94,21.94,0,0,1,21.938-204.75H153.563A21.937,21.937,0,0,1,175.5-182.812V-29.25Zm7.313-131.625H51.188a7.334,7.334,0,0,0-7.312,7.313,7.334,7.334,0,0,0,7.313,7.313h73.125a7.313,7.313,0,0,0,7.313-7.312A7.323,7.323,0,0,0,124.313-160.875Zm0,29.25H51.188a7.334,7.334,0,0,0-7.312,7.313A7.334,7.334,0,0,0,51.188-117h73.125a7.313,7.313,0,0,0,7.313-7.312A7.323,7.323,0,0,0,124.313-131.625Zm0,29.25H51.188a7.334,7.334,0,0,0-7.312,7.313,7.334,7.334,0,0,0,7.313,7.313h73.125a7.334,7.334,0,0,0,7.313-7.312A7.334,7.334,0,0,0,124.313-102.375Z" transform="translate(0 204.75)" fill="#f3853a"></path>
      </svg>
      
      </span><h6>Resume Parsing</h6></div>
      </li><li>
      <div routerLink="/dice" class="box"><span class="icon"><img src="assets/images/profile-creation-icon/dice.png" width="66">
      </span><h6>Dice</h6></div>
      </li><li>
      <div class="box"><span class="icon"><svg xmlns="http://www.w3.org/2000/svg" width="234" height="234" viewBox="0 0 234 234">
        <g id="Group_4" data-name="Group 4" transform="translate(-1582 -396.25)">
          <path id="Path_7" data-name="Path 7" d="M124.313-197.172c0-4.125,2.788-7.578,7.313-7.578A102.382,102.382,0,0,1,234-102.375a7.454,7.454,0,0,1-7.587,7.313h-102.1Z" transform="translate(1582 601)" fill="#20c997"></path>
          <path id="Path_8" data-name="Path 8" d="M102.375-73.125H211.331a6.9,6.9,0,0,1,6.993,7.77A109.656,109.656,0,0,1,109.688,29.25,109.687,109.687,0,0,1,0-80.437C0-135.875,41.16-181.743,94.194-189.1a7.068,7.068,0,0,1,8.181,7.02Z" transform="translate(1582 601)" fill="#a6e9d5"></path>
        </g>
      </svg>
      
      </span><h6>Usage</h6>
      <div *ngIf="!isusageshow" class="collapse-icon absolute" (click)="isusageshow=!isusageshow"><span class="material-icons">expand_more</span></div> 
      <div *ngIf="isusageshow" style="transform: rotate(180deg);" class="collapse-icon absolute" (click)="isusageshow=!isusageshow"><span class="material-icons">expand_more</span></div> 

      <form *ngIf="isusageshow" #submitForm="ngForm" (ngSubmit)="onSubmitForm(submitForm)">
          <div class="row">
              <div class="col-md-4 pr-0">
                  <div class="form-group" >
                      <mat-select class="form-control" required placeholder="Type" name="type"  #type="ngModel" [(ngModel)]="UsageformModel.type" >
                          <mat-option disabled >Select</mat-option>
                          <mat-option value="video" >Video</mat-option>
                          <mat-option value="audio" >Audio</mat-option>
                          <mat-option value="face" >Face</mat-option>
                      </mat-select>
                      <div class="errorMsg" *ngIf="submitForm.submitted && type.invalid">
                          <span *ngIf="type.errors.required">Type is required</span>
                      </div>
                  </div>
              </div>
              <div class="col-md-4 pr-0">
                  <div class="form-group">
                      <mat-select class="form-control" required placeholder="Month" name="dob_month"  #dob_month="ngModel" [(ngModel)]="UsageformModel.dob_month" >
                          <mat-option [value]="data.value" *ngFor="let data of monthListArray">
                              {{data.name}}
                          </mat-option>
                      </mat-select>
                      <div class="errorMsg" *ngIf="submitForm.submitted && dob_month.invalid">
                          <span *ngIf="dob_month.errors.required">Month is required</span>
                      </div>
                  </div>
              </div>
              <div class="col-md-4">
                  <div class="form-group">
                      <mat-select class="form-control" placeholder="Year" name="dob_year" #dob_year="ngModel" [(ngModel)]="UsageformModel.dob_year" required>
                          <mat-option [value]="year" *ngFor="let year of yearslist">
                              {{year}}
                          </mat-option>
                      </mat-select>
                      <div class="errorMsg" *ngIf="submitForm.submitted && dob_year.invalid">
                          <span *ngIf="dob_year.errors.required">Year is required</span>
                      </div>
                  </div>
              </div>    
          </div>
          <div class="row">
              <div class="col-sm-11 pr-0">
                  <div class="form-group">
                    <!-- <button class="btn btn-primary">download</button> -->
                    <a type="submit" style="float:right;" (click)="submitForm.onSubmit()" class="download-usage">Download</a>
                  </div>
                </div>
          </div>
          </form>
    </div>
      </li>
      
      </ul>
        </div>
      </div>
      <!-- Dashboard Dropdown -->
      <button class="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbarHeader" aria-controls="navbarHeader" aria-expanded="false" aria-label="Toggle navigation">
        <span></span><span></span><span></span><span></span>
      </button>
  </div>
</nav>

<!-- Notification Panel -->
<div class="notificationPanel"  [ngClass]=" islstopen ? 'panel-on' : 'panel-off'">
  <div (click)="OpenlfMenu()" class="panelClose"><i class="material-icons">close</i></div>
  <mat-tab-group>
      <mat-tab>
          <ng-template mat-tab-label>
          Notification
          </ng-template>

          <ng-template matTabContent>
          <div class="notificationList">
              <ul>
                  <li class="notificationItem">
                      <h4>New order has been received</h4>
                      <div class="date">2 hrs ago</div>
                      <p class="text-wrap">Candidate (kiran khair) resume as Job seeker is updated successfully by Staffing Agency</p>
                  </li>
                  <li class="notificationItem">
                      <h4>New order has been received</h4>
                      <div class="date">2 hrs ago</div>
                      <p class="text-wrap">Candidate (kiran khair) resume as Job seeker is updated successfully by Staffing Agency</p>
                  </li>
                  <li class="notificationItem">
                      <h4>New order has been received</h4>
                      <div class="date">2 hrs ago</div>
                      <p class="text-wrap">Candidate (kiran khair) resume as Job seeker is updated successfully by Staffing Agency</p>
                  </li>
              </ul>
          </div>
          
          </ng-template>
      </mat-tab>
  
      <mat-tab>
        <ng-template mat-tab-label>
          Recent Activities
        </ng-template>
        <ng-template matTabContent>
          <div class="notificationList">
              <ul>
                  <li class="notificationItem">
                      <h4>New order has been received</h4>
                      <div class="date">2 hrs ago</div>
                      <p class="text-wrap">Candidate (kiran khair) resume as Job seeker is updated successfully by Staffing Agency</p>
                  </li>
                  <li class="notificationItem">
                      <h4>New order has been received</h4>
                      <div class="date">2 hrs ago</div>
                      <p class="text-wrap">Candidate (kiran khair) resume as Job seeker is updated successfully by Staffing Agency</p>
                  </li>
                  <li class="notificationItem">
                      <h4>New order has been received</h4>
                      <div class="date">2 hrs ago</div>
                      <p class="text-wrap">Candidate (kiran khair) resume as Job seeker is updated successfully by Staffing Agency</p>
                  </li>
              </ul>
          </div>
        </ng-template>
      </mat-tab>
  </mat-tab-group>
</div>
<!-- Notification Panel -->