import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { JwtService } from './jwt.service';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { SnackbarService } from '../snackBar/snackbar.service';
import { Router } from '@angular/router';
import { LoggingMonitoringService } from '../logging.service';


@Injectable({
  providedIn: 'root'  // <- ADD THIS
})
export class ApiService {
  token: string;


  constructor(private http: HttpClient,
    private JwtToken: JwtService,
    private snackBar: SnackbarService
    , private router: Router,
    private loggingService: LoggingMonitoringService
  ) { }

  private setHeaders(): HttpHeaders {

    const headersConfig = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      //'Access-Control-Allow-Origin': '*'
    }

    if (this.JwtToken.getToken()) {
     // console.log('API login');
      headersConfig['Authorization'] = 'Bearer ' + this.JwtToken.getToken();
    }

    return new HttpHeaders(headersConfig);
  };

  private setHeadersPhenom(): HttpHeaders {
    const headersConfig = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      //'Access-Control-Allow-Origin': '*'
    }
    headersConfig['Authorization'] = 'Basic dmVyaWtsaWNrX2FwaXVzZXJzdGc6b0VlczF1ejNHc2hB';
    return new HttpHeaders(headersConfig);
  };

  POSTSURV(path: string, body: Object = {}): Observable<any> {
    return this.http.post<any>(`${path}`, JSON.stringify(body), { headers: this.setHeaders() })
      .pipe(catchError(err => {

        this.catchErr(err)
        return throwError(err);
      }));
  }

  POSTPHENOM(path: string, body: Object = {}): Observable<any> {
    return this.http.post<any>(`${path}`, JSON.stringify(body), { headers: this.setHeadersPhenom() })
      .pipe(catchError(err => {

        this.catchErr(err)
        return throwError(err);
      }));
  }


  private formatErrors(error: any) {
    return throwError(error);
  }

  httpOptions = {
    headers: new HttpHeaders({
      'Authorization': 'Bearer ' + this.JwtToken.getToken()
    })
  };

  catchErr(err) {
    //console.log(err)
    this.loggingService.logTrace('video token details', {
      'Global error details: ': err,

    });
    if (err && err['error'] !== null && err['error'].Message) {
      this.snackBar.openSnackBar(err['error'].Message, 'errorClass')
      //console.log('error type 1');
    }

    if (err['statusText'] !== null && err['statusText'] == "Unknown Error") {
      //console.log('error type 2');
      // this.snackBar.openSnackBar('Some error occurred. Please try later.', 'errorClass');
      this.snackBar.openSnackBar(err.message, 'errorClass');
      // //this.router.navigate(['/login']);
      // this.JwtToken.deleteToken();
      // this.router.navigate(['/login']);
    }
    else if (err['statusText'] !== null && err['statusText'] == "Unauthorized") {
      //console.log('error type unauth');
      this.JwtToken.deleteToken();
      document.getElementById('appHome').removeAttribute('class');
      document.getElementById('appHome').setAttribute('class', 'wrapper nonlogin');
      //this.snackBar.openSnackBar('Session expired_unauth!', 'errorClass');
      this.router.navigate(['/login']);
      // //this.router.navigate(['/login']);
      // this.JwtToken.deleteToken();
      // this.router.navigate(['/login']);
    }

    else if (err && err['error'] !== null && err['error'].message && err['error'].message == "Unknown Error") {
      //console.log('error type 3');
      this.snackBar.openSnackBar('Session expired!', 'errorClass')
    }
    else if (err && err['error'] !== null && err['error'].text) {
      //console.log('error type 4');
      this.snackBar.openSnackBar(err['error'].text, 'errorClass')
    }
    else {
      //console.log('error type 5');
      this.snackBar.openSnackBar(err['statusText'], 'errorClass')
      this.snackBar.openSnackBar(err['error'].Message, 'errorClass')
    }
  }

  GET<ResultModel>(path: string, searchParams: HttpParams = new HttpParams()): Observable<ResultModel> {
    return this.http.get<ResultModel>(`${environment.API_PATH}${path}`, { headers: this.setHeaders(), params: searchParams }
    ).pipe(catchError(err => {

      this.catchErr(err)
      // return throwError("Some error occurred. Please try later.");
      return throwError(err.message);
    }));
  }

  POST<ResultModel>(path: string, body: Object = {}): Observable<ResultModel> {
    return this.http.post<ResultModel>(`${environment.API_PATH}${path}`, JSON.stringify(body), { headers: this.setHeaders() })
      .pipe(catchError(err => {
        console.log(err)
        this.catchErr(err)
        return throwError(err);
      }));

  }

  POSTBG(path: string, body: Object = {}): Observable<any> {
      return this.http.post<any>(`${environment.API_PATH}${path}`, JSON.stringify(body), { headers: this.setHeaders() })
        .pipe(catchError(err => {

          this.catchErr(err)
          return throwError(err);
        }));
    }

  updateUserProfileFormRequest<ResultModel>(formData,path): Observable<ResultModel> {
    return this.http.post<ResultModel>(`${environment.API_PATH_VDIAL}${path}`, formData, this.httpOptions);
  }

  POSTVDIAL<ResultModel>(path: string, body: Object = {}): Observable<ResultModel> {
    return this.http.post<ResultModel>(`${environment.API_PATH_VDIAL}${path}`, JSON.stringify(body), { headers: this.setHeaders() })
      .pipe(catchError(err => {
        this.catchErr(err)
        return throwError(err);
      }));
  }
//Manu Datta: 20210512, for Veridail Get API
  GETVDIAL<ResultModel>(path: string, searchParams: HttpParams = new HttpParams()): Observable<ResultModel> {
    return this.http.get<ResultModel>(`${environment.API_PATH_VDIAL}${path}`, { headers: this.setHeaders(), params: searchParams }
    ).pipe(catchError(err => {
      this.catchErr(err)
      // return throwError("Some error occurred. Please try later.");
      return throwError(err.message);
    }));
  }

  POSTJOBSEEKER_IMAGE_VDIAL<ResultModel>(path, formData,req): Observable<ResultModel> {

    var token =  window.localStorage['access_token'];
    const  httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.post<ResultModel>(`${environment.API_PATH_VDIAL}${path}`, req, httpOptions);
  }

  FaceBiometrics_IMAGE_Save<ResultModel>(path, formData,req): Observable<ResultModel> {
    return this.http.post<ResultModel>(`${environment.API_PATH}${path}`, req);
  }
  FaceBiometrics_IMAGE_CheckCandidateEnroll<ResultModel>(path,req): Observable<ResultModel> {
    return this.http.post<ResultModel>(`${environment.API_PATH}${path}`,req);
  }
  FaceBiometrics_InterviewListBasedOnCandidateId<ResultModel>(path,req): Observable<ResultModel> {
    return this.http.post<ResultModel>(`${environment.API_PATH}${path}`,req);
  }
  FaceBiometrics_CandidateVideoUrl<ResultModel>(path, formData,req): Observable<ResultModel> {
    return this.http.post<ResultModel>(`${environment.API_PATH}${path}`, req);
  }

  // updateVideoScheduleEmailAcceptanceStatus(EmailAcceptanceModel:any): Observable<any> {
  //   return this.http.POST(${apiPaths.UpdateEmailAcceptance_status_VideoSchedule},EmailAcceptanceModel);
  // }

  FaceBiometrics_IMAGE_Verify<ResultModel>(path, formData,req): Observable<ResultModel> {

    // var token =  window.localStorage['access_token'];
    // const  httpOptions = {
    //   headers: new HttpHeaders({'Access-Control-Allow-Origin':'*',
    //     'Authorization': 'Bearer ' + token
    //   })
    // };
    return this.http.post<ResultModel>(`${environment.API_PATH}${path}`, req);
  }
  POSTJOBSEEKER_DOCUMENTTOS3_VDIAL<ResultModel>(path, formData): Observable<ResultModel> {
    // var token =  this.JwtToken.getToken();
    //Manu Datta: 20210601, for updated token
    var token =  window.localStorage['access_token'];
    const  httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + token
      })

    };
    return this.http.post<ResultModel>(`${environment.API_PATH_VDIAL}${path}`, formData, httpOptions);

    // httpOptions = {
    //   headers: new HttpHeaders({
    //     'Authorization': 'Bearer ' + this.JwtToken.getToken()
    //   })
    // };
  }

  GETJOBSEEKER_S3DOCUMENTURL<ResultModel>(path, ID): Observable<ResultModel> {

   const  httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.JwtToken.getToken()
      })
    };
    return this.http.post<ResultModel>(`${environment.API_PATH_VDIAL}${path}`, ID, httpOptions);
  }


  PUT<ResultModel>(path: string, body: object = {}): Observable<ResultModel> {
    return this.http.put<ResultModel>(`${environment.API_PATH}${path}`, JSON.stringify(body), { headers: this.setHeaders() })
      .pipe(catchError(err => {
        this.catchErr(err)
        // return throwError("Some error occurred. Please try later.");
        return throwError(err.message);
      }));
  }

  DELETE<ResultModel>(path: string): Observable<ResultModel> {
    return this.http.delete<ResultModel>(`${environment.API_PATH}${path}`, { headers: this.setHeaders() })
      .pipe(catchError(err => {
        this.catchErr(err)
        // return throwError("Some error occurred. Please try later.");
        return throwError(err.message);
      }));
  }

  GET_RECOMMENDATION(path):Observable<any>{
    const  httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.JwtToken.getToken()
      })
    };
    return this.http.get(`${environment.API_PATH}${path}`, httpOptions);
  }

  POSTVKPService<ResultModel>(path: string, body: Object = {}): Observable<ResultModel> {
    //debugger
    return this.http.post<ResultModel>(`${environment.API_PATH_VKService}${path}`, JSON.stringify(body), { headers: this.setHeaders() })
      .pipe(catchError(err => {
        this.catchErr(err)
        return throwError(err);
      }));
  }

  GetThirdpartyJobPullService<ResultModel>():Observable<ResultModel>{
    const  httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',

      })
    };
    return this.http.get<ResultModel>(`${environment.API_PATH_VKTPGetJobDetailsService}`, httpOptions);
  }
}
